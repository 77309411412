import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Form from '../../../../components/Benefits/Vauchers/Form';
import { DateTime } from "luxon";

import { AuthContext,
         ContentContext,
         ConfigurationContext } from '../../../../context';

import ActionBox from '../../../../components/elements/ActionBox';
import SelectCustom from '../../../../components/elements/SelectCustom';
import LabelCustom from '../../../../components/elements/LabelCustom';
import InputCustom from '../../../../components/elements/InputCustom'
import InputCheckboxCustom from '../../../../components/elements/InputCheckboxCustom';
import LoaderComponent from '../../../../components/elements/LoaderComponent';
import SuccessForm from '../../../../components/elements/SuccessForm';
import ModalImageProfile from '../../../../components/elements/Modals/ModalImageProfile';
import Confirmation from '../../../../components/elements/Dialogs/Confirmation';
import FormTopMessage from '../../../../components/elements/FormTopMessage';
import FormBottomMessage from '../../../../components/elements/FormBottomMessage';
import ButtonTextCustom from '../../../../components/elements/ButtonTextCustom';
import ButtonCustom from '../../../../components/elements/ButtonCustom';
import successImage from '../../../../assets/images/img_success_benefits.svg';
import Segmentation from '../../../../components/elements/Dialogs/Segmentation';

import ScheduleDateTimeRange from '../../../../components/elements/Dialogs/ScheduleDateTimeRange';
import Products from '../../../../components/elements/Dialogs/Products';
import SegmentationUsers from '../../../../components/elements/Dialogs/SegmentationUsers';
import SegmentationStores from '../../../../components/elements/Dialogs/SegmentationStores';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { BiSolidDownArrow } from "react-icons/bi";

import { dataObjectBenefits,
         validateOnlyIntegers,
         validateNoTagsHTML,
         getNameLanguage,
         dataObjectVouchers } from '../../../../helpers/functions';

import { TitleIcon, 
         CalendarMonthIcon,
         LanguageIcon,
         FilterAltIcon,
         StockProductos,
         SegmentarionIcon } from '../../../../helpers/icons';

import 'react-image-crop/dist/ReactCrop.css';

const WrapperStyled = styled.div`
    width: 100%;
    .loader{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }
    .voucher_segmentation{
        width: 100%;
        //background-color: #F7F8FC;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        .head{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            color: ${props => props.error ? `rgb(${props.pancolors.red})` : `rgb(${props.pancolors.black_overwhite})`};
            .title{
                margin-left: 5px
            }
            .icon{
                svg{
                    fill: rgb(72, 77, 99);
                }
            }
        }
        .segmentation_action{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 10px;
            .bt_open_segment{
                padding: 0 0 0 3px;
                p{
                    margin: 0;
                }
            }
        }
        .segmentation_type{
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            padding-left: 3px;
            .option{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 30px;
                cursor: pointer;
                .radio{
                    border: 2px solid rgb(109, 105, 239);
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                }
                .text{
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 600;
                    color: ${props => props.error ? `rgb(${props.pancolors.red})` : `rgb(${props.pancolors.black_overwhite})`};
                    margin-left: 5px;
                }
                :hover{
                    .radio{
                        background-color: rgb(109, 105, 239);
                    }
                    .text{
                        color: rgb(109, 105, 239);
                    }
                }
                &.selected{
                    .radio{
                        background-color: rgb(109, 105, 239);
                    }
                    .text{
                        color: rgb(109, 105, 239);
                    }
                }
            }
        }
    }
    .content_form.show{
        display: flex;
    }
    .content_form.hide{
        display: none;
    }
    .box_line{
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
    .box_form{
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        .box_column{
            .action_box_evaluation{
            }
            .media_slides{
                margin-top: 30px;
                margin-bottom: 30px;
                .no_multimedia_content{
                    box-sizing: border-box;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    border: 1px dashed ${props => `rgb(${props.pancolors.middle_gray})`};
                    color: ${props => `rgb(${props.pancolors.dark_gray})`};
                    font-weight: 600;
                }
                &.hide{
                    display: none;
                }
            }
            .upload_media{
                &.hide{
                    display: none;
                }
            }
            .action_box_segmentation{
                margin-bottom: 30px;
            }
            .box_categories{
                margin-bottom: 20px;
            }
            .box_fields{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 0;
                >.title{
                    margin-bottom: 15px;
                    color: ${props => `rgb(${props.pancolors.black})`};
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 600;
                }
                .field{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 25px;
                    :last-child{
                    }
                    .datetime_activate_at{
                        width: 100%;
                        .MuiOutlinedInput-notchedOutline{
                            border-color: rgba(0, 0, 0, 0.23);
                        }
                        &.error{
                            .MuiOutlinedInput-notchedOutline{
                                border: 2px solid ${props => `rgb(${props.pancolors.red})`}!important;
                            }
                        }
                    }
                }
                .box_products_label{
                    width: 100%;
                    margin-bottom: 25px;
                    .field_product_labels{
                        margin-bottom: 0!important;
                    }
                }
            }
        }
        &.show{
            display: grid;
        }
        &.hide{
            display: none;
        }
    }
    .box_lang{

        &.multilang{
            box-sizing: border-box;
            padding: 0 30px 0 30px;
            background-color: ${props => `rgb(${props.pancolors.light_white})`};
            margin-bottom: 20px;
            
            .head_form_lang{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: ${props => `rgb(${props.pancolors.light_white})`};
                box-sizing: border-box;
                padding: 20px 0 20px 0;
                
                >.title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon{
                        svg{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text{
                        font-size: 22px;
                        line-height: 22px;
                        font-weight: 700;
                        color: ${props => `rgb(${props.pancolors.dots})`};
                        margin-left: 10px;
                    }
                }
            }
        }
    }
`

const CreateContainer = (props) => {
    
    const { editBenefit,
            getBenefit,
            setBenefit,
            benefit,
            basicData,
            uploadFile,
            getByPassImageUploadRoute } = useContext(ContentContext);

    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);

    const navigate = useNavigate();
    const { id_benefit } = useParams();

    const [ openModalImageProfile, setOpenModalImageProfile ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ haveErrorForm, setHaveErrorForm ] = useState(false);
    const [ errorFormMessage, setErrorFormMessage ] = useState({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"});
    const [ contentByLang, setContentByLang ] = useState({});
    const [ publishDateError, setPublishDateError ] = useState(false);
    const [ publicationDate, setPublicationDate ] = useState("");
    const [ immediatePublication, setImmediatePublication ] = useState(false);
    const [ segments, setSegments ] = useState([]);
    const [ segmentsError, setSegmentsError ] = useState(false);
    const [ confirmationOpen, setConfirmationOpen ] = useState(false);
    const [ noSegments, setNoSegments ] = useState(false);
    const [ segmentationOpen, setSegmentationOpen ] = useState(false);
    
    const [ startDate, setStartDate ] = useState({});
    const [ endDate, setEndDate ] = useState("");
    const [ timezone, setTimezone ] = useState(dayjs.tz.guess());
    const [ changeSegmentationType, setChangeSegmentationType ] = useState("");
    const [ openScheduleDateTimeRange, setOpenScheduleDateTimeRange ] = useState(false);
    const [ changeSegmentationName, setChangeSegmentationName ] = useState("");
    const [ segmentationUsersModalOpen, setSegmentationUsersModalOpen ] = useState(false);
    const [ segmentationStoresModalOpen, setSegmentationStoresModalOpen ] = useState(false);
    const [ noPoints, setNoPoints ] = useState(false);
    const [ productModalOpen, setProductModalOpen ] = useState(false);
    const [ counterProducts, setCounterProducts ] = useState(0);
    const [ productList, setProductList ] = useState([]);
    const [ segmentationStoresList, setSegmentationStoresList ] = useState([]);
    const [ segmentationUsersList, setSegmentationUsersList ] = useState([]);
    const [ showLanguage, setShowLanguage ] = useState(null);
    const [ vigencyDateError, setVigencyDateError ] = useState(false);
    const [ langHaveError, setLangHaveError ] = useState([]);

    const [ minmaxGeneral, setMinmaxGeneral ] = useState({ name: "min_max_general",
                                                           value: "", 
                                                           required: false, 
                                                           disabled: false, 
                                                           ref: "min_max_general", 
                                                           label: "Selecciona tus productos", 
                                                           placeholder: null,
                                                           help: "",
                                                           error: false });

    const [ subType, setSubType ] = useState({ value: "", 
                                         required: true, 
                                         disabled: false, 
                                         ref: "sub_type", 
                                         label: "Tipo de voucher", 
                                         placeholder: "Seleccione tipo de voucher ...",
                                         help: "",
                                         error: false })

    const [ allProducts, setAllProducts ] = useState({ name: "all_products",
                                                       value: "", 
                                                       required: false, 
                                                       disabled: false, 
                                                       ref: "min_max_general", 
                                                       label: "Todo los productos (incluye todo el catálogo)", 
                                                       placeholder: null,
                                                       help: "",
                                                       error: false });
                                                       
    const [ minGeneral, setMinGeneral ] = useState({ name: "min_general",
                                                     value: "", 
                                                     required: true, 
                                                     disabled: false, 
                                                     ref: "min_general", 
                                                     label: "Mínimo", 
                                                     placeholder: "...",
                                                     help: "",
                                                     error: false });

    const [ maxGeneral, setMaxGeneral ] = useState({ name: "man_general",
                                                     value: "", 
                                                     required: true, 
                                                     disabled: false, 
                                                     ref: "man_general", 
                                                     label: "Máximo", 
                                                     placeholder: "...",
                                                     help: "",
                                                     error: false });

    const [ points, setPoints ] = useState({ value: "", 
                                             required: true, 
                                             disabled: false, 
                                             ref: "points", 
                                             label: "Cantidad de puntos", 
                                             placeholder: "Ingresar puntos/costo de este Beneficio ...",
                                             help: "",
                                             error: false });

    const [ stock, setStock ] = useState({ value: "", 
                                           required: true, 
                                           disabled: false, 
                                           ref: "stock", 
                                           label: "Stock", 
                                           placeholder: "Ingresar el stock disponible para este Beneficio ...",
                                           help: "",
                                           error: false });

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {

        getBenefit(id_benefit);

    }, [])

    useEffect(() => {

        return () => { 
            setBenefit(null); 
            setContentByLang({});
        }
    }, [])

    useEffect(() => {
        
        if(basicData && (benefit && Object.keys(benefit).length)){
            
            let contentByLangUpdate = {...contentByLang};
            userCountrySelected.languages.map((lang, index) => {
                let key = lang.language_identifier;

                if(benefit.translations[key] !== undefined){
                        contentByLangUpdate[key] = dataObjectVouchers(benefit.translations[key]);
                }else{
                    contentByLangUpdate[key] = dataObjectVouchers(null);
                }
            })

            setStartDate(dayjs(benefit.date_init).tz(dayjs.tz.guess()).format());
            setEndDate(dayjs(benefit.date_finish).tz(dayjs.tz.guess()).format());
            setPublicationDate(dayjs(benefit.publication_date).tz(dayjs.tz.guess()).format());
            //setSegments(benefit.segments);
            setSegmentationUsersList(benefit.segments.users);
            setSegmentationStoresList(benefit.segments.pos);
            setContentByLang(contentByLangUpdate);

            setSubType(prevState => ({ ...prevState, value: benefit.sub_type }));
            setAllProducts(prevState => ({ ...prevState, value: benefit.all_products }));
            setMinGeneral(prevState => ({ ...prevState, value: benefit.min }));
            setMaxGeneral(prevState => ({ ...prevState, value: benefit.max }));
            setPoints(prevState => ({ ...prevState, value: benefit.points }));

            if(parseInt(benefit.points) === 0){
                setPoints(prevState => ({ ...prevState, value: "0", disabled: true }));
                setNoPoints(true);
            }else{
                setPoints(prevState => ({ ...prevState, value: benefit.points }));
            }
            
            if(benefit.all_products || (parseInt(benefit.min) && parseInt(benefit.max))){
                setMinmaxGeneral(prevState => ({ ...prevState, value: true }));
            }

            setProductList(benefit.products);
            
                                                       
        }
    
      return () => {}
    }, [benefit, basicData])

    const pushCountrySelected = () => {
        let segments_update = {...benefit.segments};
        segments_update.countries = [userCountrySelected.id_country];
        setSegments(segments_update);
    }

    useEffect(() => {
        if(benefit){
            pushCountrySelected();
        }
      return () => {}
    }, [benefit])
    
    const handleFormElementChange = (name, value) => {
        let valid = true;
        switch (name) {
            case 'timezone':
                setTimezone(value);
                break;
            case 'stock':
                
                let updateStock = {...stock};

                valid = validateOnlyIntegers(value);
                if(!valid){
                    updateStock.error = true;
                }else{
                    updateStock.error = false;
                }
                updateStock.value = value;
                setStock(updateStock);

                break;
            case "sub_type":
                let subtype_update = {...subType};
                subtype_update.value = value;
                setSubType(subtype_update);
                break
            case "points":
                let points_update = {...points};
                points_update.value = value;
                setPoints(points_update);

                break
            case 'no_points':
                setNoPoints(!noPoints);
                let points_update_ = {...points};
                if(value){
                    points_update_.value = '0';
                    points_update_.disabled = true;
                }else{
                    points_update_.value = '';
                    points_update_.disabled = false;
                }
                setPoints(points_update_);
                break;
            case 'immediate_publication':
                setImmediatePublication(!immediatePublication);
                break;
            default:
                break;
        }

        return null;
    }

    const handleSubmit = async () => {
        
        try{

            setErrorFormMessage({top: "", bottom: ""})
            setHaveErrorForm(false);
            setLoadingForm(true);
            await handleValidateForm();
            setConfirmationOpen(true);

        }catch(err){

            setErrorFormMessage({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"})
            setHaveErrorForm(true);
            setLoadingForm(false);
            
        }

    }

    const handleSubmitFinal = async () => {
        
        try{

            setErrorFormMessage({top: "", bottom: ""})
            setHaveErrorForm(false);
            setLoadingForm(true);
            await handleValidateForm();
            await handleUploadFile();
            await handleSendForm();

        }catch(err){

            setErrorFormMessage({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"})
            setHaveErrorForm(true);
            setLoadingForm(false);
            
        }

    }

    const handleValidateForm = () => {
        return new Promise((resolve, reject) => {

            let haveError = false;

            if(Object.keys(contentByLang).length){
                let updateLangs = {...contentByLang};
                Object.keys(contentByLang).map(function(key_langs) {
                    let updateLang = {...contentByLang[key_langs]};
                    let lang = contentByLang[key_langs];
                    if(Object.keys(lang).length){
                        Object.keys(lang).map(function(key_lang) {
                            
                            if(lang[key_lang].required){
                                if(!lang[key_lang].value.length){
                                    
                                    if(key_lang !== "cover_image"){
                                        haveError = true;
                                        updateLang[key_lang].error = true;
                                    }

                                }else{

                                    updateLang[key_lang].error = false;
                                    if(key_lang === "title"){
                                        if(validateNoTagsHTML(updateLang[key_lang].value)){
                                            haveError = true;
                                            updateLang[key_lang].error = true;
                                        }
                                    }

                                    if(key_lang === "stock" || key_lang === "points"){

                                        let valid = validateOnlyIntegers(updateLang[key_lang].value);
                                        
                                        if(!valid){
                                            haveError = true;
                                            updateLang[key_lang].error = true;
                                        }
        
                                    }
                                    
                                }

                                if(key_lang === "cover_image"){
                                    if(!updateLang[key_lang].value.length && !updateLang[key_lang].cropped_image.length){
                                        haveError = true;
                                        updateLang[key_lang].error = true;
                                    }else{
                                        updateLang[key_lang].error = false;
                                    }
                                }
                                
                            }
                        })
                    }
                    updateLangs[key_langs] = updateLang;
    
                    
                })
                setContentByLang(updateLangs);
            }
            
            let subtype_update = {...subType};
            if(!subType.value.length){
                subtype_update.error = true;
                haveError = true;
            }else{
                subtype_update.error = false;
            }
            setSubType(subtype_update);

            let points_update = {...points};
            if(!points.value.length){
                points_update.error = true;
                haveError = true;
            }else{
                points_update.error = false;
            }
            setPoints(points_update);

            if(!startDate && !dayjs(startDate).isValid()){
                haveError = true;
                setVigencyDateError(true);
            }
            
            if(!endDate && !dayjs(endDate).isValid()){
                haveError = true;
                setVigencyDateError(true);
            }

            if(startDate && endDate){
                if(!dayjs(endDate).isAfter(dayjs(startDate))){
                    haveError = true;
                    setVigencyDateError(true);
                }else{
                    setVigencyDateError(false);
                }
            }

            if(!immediatePublication){
                if(!dayjs(publicationDate).isValid()){
                    haveError = true;
                    setPublishDateError(true);
                }else{
                    setPublishDateError(false);
                }
            }else{
                setPublishDateError(false);
            }

            if(!haveError){
                resolve(true);
            }

            reject(new Error("error valida"));

        })
        

    }


    const handleUploadFile = () => {
        return new Promise((resolve, reject) => {

            let count_images_to_upload = imagesToUpload();
            let count_uploaded = 0;

            if(count_images_to_upload === count_uploaded){
                resolve(true);
            }

            let updateLangs = {...contentByLang};
            Object.keys(updateLangs).map(function(key_langs) {
                if(updateLangs[key_langs].cover_image.cropped_image){
                    const datetime = DateTime.fromMillis(Date.now());

                    let cropped_image = updateLangs[key_langs].cover_image.cropped_image;
                    let upload_route = null;

                    const payload = { name: "image-"+datetime.toMillis()+".jpeg",
                                      file: updateLangs[key_langs].cover_image.cropped_image };
                          
                    const uploadRoute = getByPassImageUploadRoute(payload);

                    uploadRoute.then((res) => {
                        upload_route = res;

                        const uploaded = uploadFile(cropped_image, upload_route);

                        uploaded.then(uploaded_res => {
                            updateLangs[key_langs].cover_image.cropped_image = "";
                            updateLangs[key_langs].cover_image.upload_route = "";
                            updateLangs[key_langs].cover_image.value = uploaded_res;

                            setContentByLang(updateLangs);
                            count_uploaded ++;
                            
                            if(count_images_to_upload === count_uploaded){
                                resolve(true);
                            }

                        })
                    })
                }
            })
        })
    }

    const imagesToUpload = () => {
        let count_images = 0;
        let updateLangs = {...contentByLang};
        Object.keys(updateLangs).map(function(key_langs) {
            if(updateLangs[key_langs].cover_image.cropped_image){
                count_images++;
            }
        })
        return count_images;
    }
    
    const handleSendForm = () => {

        return new Promise((resolve, reject) => {

            handleSubmitForm();
            resolve(true);

        })

    }

    const handleSubmitForm = () => {
        
        const contentByLangToSend = cloneDeep(contentByLang);
        let payload = {};
        let request;

        let p_date = "";
        let _date_finish = "";
        let _date_init = "";

        const today = dayjs().format('YYYY-MM-DD HH:mm');

        if(dayjs.tz.guess() === timezone){
            _date_finish = dayjs(dayjs(endDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            _date_init = dayjs(dayjs(startDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            if(!immediatePublication){
                p_date = dayjs(dayjs(publicationDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            }else{
                p_date = dayjs().utc().toISOString();
            }
        }else{
            _date_finish = dayjs.tz(dayjs(endDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            _date_init = dayjs.tz(dayjs(startDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            if(!immediatePublication){
                p_date = dayjs.tz(dayjs(publicationDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            }else{
                p_date = dayjs.tz(today, timezone).utc().toISOString();
            }
        }

        let normalizeProductsList = [];
        if(productList.length){
            productList.map((product) => {
                normalizeProductsList.push(product._id);
            })
        }

        payload = {

            benefit_id: id_benefit,
            type: 'voucher',
            sub_type: subType.value,
            
            segments: JSON.stringify(segments),
            products:  JSON.stringify(productList),

            publication_date: p_date,
            date_finish: _date_finish,
            date_init: _date_init,

            points: points.value,
            min: minGeneral.value ? minGeneral.value : "0",
            max: maxGeneral.value ? maxGeneral.value : "0",
            all_products: allProducts.value ? true : false,
            translations: JSON.stringify(handleFormatRequestTranslations(contentByLangToSend)),
        };
        
        request = editBenefit(payload);
            
        request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setErrorFormMessage({top: err.message, bottom: err.message})
                setHaveErrorForm(true);
                setLoadingForm(false);
            })
        
    }

    const handleCancelSubmit = () => {
        setLoadingForm(false);
    }
    const handleConfirmationCallback = () => {
        setLoadingForm(false);
        setConfirmationOpen(false);
        setSegmentationOpen(true);
    }
    
    const handleShowLanguage = (lang) => {
        if(lang !== showLanguage){
            setShowLanguage(lang);
        }else{
            setShowLanguage(null);
        }
    }

    const handleFormatRequestTranslations = (data) => {

        if(Object.keys(data).length){
            let updateLangs = {...data};
            Object.keys(updateLangs).map(function(key) {
                Object.keys(updateLangs[key]).map(function(inner_key) {
                    if(inner_key != 'stock' || inner_key != 'points'){
                        updateLangs[key][inner_key] = updateLangs[key][inner_key].value ? updateLangs[key][inner_key].value : "";
                    }
                })
            })
            
            return updateLangs;
        }
        return null;
    }

    const handleSaveVigency = () => {
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    const handleProductsModal = () => {
        setProductModalOpen(!productModalOpen);
    }

    const handleChangeSegmentationType = (type) => {
        switch (type) {
            case "region":
                setChangeSegmentationName("Región / Mapa");
                break;
            case "users":
                setChangeSegmentationName("Usuarios");
                break;
            case "stores":
                setChangeSegmentationName("Tiendas");
                break;
            default:
                break;
        }
        setChangeSegmentationType(type);
    }
    
    const handleOpenSegment = () => {
        switch (changeSegmentationType) {
            case "region":
                setSegmentationOpen(!segmentationOpen);
                break;
            case "users":
                setSegmentationUsersModalOpen(!segmentationUsersModalOpen);
                break;
            case "stores":
                setSegmentationStoresModalOpen(!segmentationStoresModalOpen);
                break;
            default:
                break;
        }
    }
    
    const handleVigencyRange = () => {
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    useEffect(() => {
        if(userCountrySelected){
            if(userCountrySelected.languages.length){
                setShowLanguage(userCountrySelected.languages[0].language_identifier);
            }
        }
    return () => {}
    }, [userCountrySelected])
    
    if(!benefit || !Object.keys(basicData).length || !Object.keys(startDate).length){
        return(
            <div className='loader'>
                <LoaderComponent size={40} color={panColors.dots} />
            </div>
        )
    }

    return (
        <WrapperStyled pancolors={panColors} className="box_create_user">
            {!success ? 
            <>
            
            {openModalImageProfile &&
                <ModalImageProfile 
                    openModalImageProfile={openModalImageProfile} 
                    setOpenModalImageProfile={setOpenModalImageProfile} 
                />
            }

            <Confirmation 
                open={confirmationOpen} 
                setOpen={setConfirmationOpen}
                type={'course'}
                data={segments}
                segments={segments}
                setSegments={setSegments}
                noSegments={noSegments}
                setNoSegments={setNoSegments}
                handleCallback={handleConfirmationCallback}
                handleSubmit={handleSubmitFinal}
                handleCancelSubmit={handleCancelSubmit}
                description={`El voucher será publicado ${immediatePublication ? dayjs().format('YYYY-MM-DD HH:mm') : dayjs(publicationDate).format('YYYY-MM-DD HH:mm')} después de confirmar y guardar de esta acción.`}
            />

            <Segmentation 
                open={segmentationOpen} 
                setOpen={setSegmentationOpen}
                segments={segments}
                setSegments={setSegments}
                //segments={contentByLang.segments}
            />

            <Products 
                open={productModalOpen} 
                setOpen={setProductModalOpen}
                productList={productList}
                setProductList={setProductList}
                setCounterProducts={setCounterProducts}
                allProducts={allProducts}
                setAllProducts={setAllProducts}
                minGeneral={minGeneral}
                setMinGeneral={setMinGeneral}
                maxGeneral={maxGeneral}
                setMaxGeneral={setMaxGeneral}
                minmaxGeneral={minmaxGeneral}
                setMinmaxGeneral={setMinmaxGeneral}
            />

            <SegmentationUsers 
                open={segmentationUsersModalOpen} 
                setOpen={setSegmentationUsersModalOpen}
                segmentationUsersList={segmentationUsersList}
                setSegmentationUsersList={setSegmentationUsersList}
            />

            <SegmentationStores
                open={segmentationStoresModalOpen} 
                setOpen={setSegmentationStoresModalOpen}
                segmentationStoresList={segmentationStoresList}
                setSegmentationStoresList={setSegmentationStoresList} 
            />

            <ScheduleDateTimeRange 
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate} 
                open={openScheduleDateTimeRange} 
                setOpen={setOpenScheduleDateTimeRange}
                handleSubmit={handleSaveVigency}
                timezone={timezone}
                setTimezone={setTimezone}
            />

            <div className='box_line'>
                <div className="title">
                    Datos del Voucher
                </div>
            </div>

            <div className="box_form">
                <div className="box_column">
                    <div className="box_fields">
                        
                        



                        <SelectCustom 
                            name={subType?.ref}
                            label={subType?.label}
                            placeholder={subType?.placeholder}
                            onChange={handleFormElementChange}
                            className={`field field_${subType?.ref}${subType?.error ? " error" : ""}`}
                            type="text"
                            value={subType?.value}
                            help={""}
                            isRequired={subType?.required}
                            styles={ formStyle }
                            label_icon={<FilterAltIcon />}
                            tooltip_content={subType?.tooltip}
                            haveError={subType?.error}
                            data={basicData?.benefit_types}
                        />
                        <InputCustom 
                            name={points?.ref}
                            label={points?.label}
                            placeholder={points?.placeholder}
                            onChange={handleFormElementChange}
                            onKeyDown={null}
                            className={`field field_${points?.ref}${points?.error ? " error" : ""}`}
                            type="text"
                            value={points?.value}
                            help={points?.help}
                            isRequired={points?.required}
                            styles={ formStyle }
                            label_icon={<TitleIcon />}
                            tooltip_content={points?.tooltip}
                            haveError={points?.error}
                            disabled={points?.disabled}
                        />
                        <InputCheckboxCustom 
                            name={"no_points"}
                            label={"Sin costo en puntos"}
                            placeholder={""}
                            onChange={handleFormElementChange}
                            onKeyDown={null}
                            className={`field field_no_points`}
                            type="text"
                            value={noPoints}
                            help={""}
                            isRequired={false}
                            styles={ formStyle }
                            label_icon={null}
                            labelPosition={"right"}
                            tooltip_content={null}
                            haveError={false}
                        />

                        <ActionBox 
                            className="action_box_segmentation"
                            icon={<StockProductos />}
                            title={"Productos incluidos *"}
                            actionButtonText={"Editar Productos"}
                            helpText={`Cantidad total de productos cargados: ${allProducts.value ? 'Todos los productos' : productList ? productList.length : 0}`}
                            onClick={handleProductsModal}
                            error={vigencyDateError}
                        /> 

                    </div>
                </div>
                <div className='box_column'>
                    <div className="box_fields">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="field file_publication_date">
                                <LabelCustom 
                                    name="label_activate_at"
                                    label="Fecha de Publicación *"
                                    onChange={handleFormElementChange}
                                    className="field field_activate_at"
                                    styles={ formStyle }
                                    label_icon={<CalendarMonthIcon />}
                                />
                                <DateTimePicker
                                    ampm={false}
                                    className={`datetime_activate_at ${publishDateError ? " error" : ""}`}
                                    disableFuture={false}
                                    disablePast={true}
                                    //timezone="America/Argentina/Buenos_Aires"
                                    value={publicationDate ? dayjs(publicationDate) : ""}
                                    onChange={(newValue) => setPublicationDate(newValue)}
                                    disabled={immediatePublication}
                                />
                            </div>
                        </LocalizationProvider>

                        <SelectCustom 
                            name={"timezone"}
                            label={""}
                            placeholder={"Seleccionar zona horaria ..."}
                            onChange={handleFormElementChange}
                            className={`field field_timezone`}
                            type="text"
                            value={timezone}
                            help={null}
                            isRequired={false}
                            styles={ formStyle }
                            label_icon={<FilterAltIcon />}
                            tooltip_content={null}
                            haveError={false}
                            data={basicData?.valoresUTC}
                        />

                        <InputCheckboxCustom 
                            name={"immediate_publication"}
                            label={"Publicacion inmediata"}
                            placeholder={""}
                            onChange={handleFormElementChange}
                            onKeyDown={null}
                            className={`field field_immediate_publication`}
                            type="text"
                            value={immediatePublication}
                            help={""}
                            isRequired={false}
                            styles={ formStyle }
                            label_icon={null}
                            labelPosition={"right"}
                            tooltip_content={null}
                            haveError={false}
                        />
                        {Object.keys(startDate).length ?
                        <ActionBox 
                            className="action_box_segmentation"
                            icon={<CalendarMonthIcon />}
                            title={"Vigencia del beneficio *"}
                            actionButtonText={"Editar Vigencia"}
                            helpText={`Vigencia establecida del ${(startDate && endDate) ? dayjs(startDate).format('YYYY-MM-DD HH:mm') + ' al ' + dayjs(endDate).format('YYYY-MM-DD HH:mm') : '...'}`}
                            onClick={handleVigencyRange}
                            error={vigencyDateError}
                        /> : ""}

                        

                        <div className='voucher_segmentation'>
                            <div className="head">
                                <div className={`icon`}>
                                    <SegmentarionIcon />
                                </div>
                                <div className={`title`}>
                                    Segmentación
                                </div>
                            </div>
                            <div className='segmentation_type'>
                                <div className={`option${changeSegmentationType === "region" ? " selected" : ""}`} onClick={() => handleChangeSegmentationType('region')}>
                                    <div className='radio'></div>
                                    <div className='text'>Región/Mapa</div>
                                </div>
                            </div>
                            {changeSegmentationName.length ?
                            <div className='segmentation_action'>
                                <ButtonTextCustom 
                                    className="bt_open_segment"
                                    text={`Crear Segmentación ${changeSegmentationName}`}
                                    onClick={handleOpenSegment}

                                    icon={null}
                                    background={{ normal: "", hover: "" }}
                                    border={{ normal: "", hover: "" }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                />
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>

            {userCountrySelected?.languages?.length ? 
                <>
                    <FormTopMessage 
                        haveError={haveErrorForm}
                        text={errorFormMessage.top}
                    />

                    {
                        userCountrySelected?.languages.map((lang, index) => {
                            return(
                                <div className={`box_lang ${userCountrySelected?.languages?.length > 1 ? " multilang" : ""}${(langHaveError.filter(langerror => langerror === lang.language_identifier).length ? ' error' : '')}${showLanguage === lang.language_identifier ? ' open' : ''}`}>
                                    {userCountrySelected?.languages?.length > 1 &&
                                    <div className="head_form_lang">
                                        <div className="title">
                                            <div className="icon">
                                                <LanguageIcon />
                                            </div>
                                            <div className="text">
                                                Voucher en { getNameLanguage(userCountrySelected?.languages, lang.language_identifier) }
                                            </div>
                                        </div>
                                        {userCountrySelected?.languages.length ?
                                        <ButtonTextCustom 
                                            className="bt_show_more_info"
                                            text={`${showLanguage == lang.language_identifier ? 'Cerrar' : 'Abrir'}`}
                                            onClick={() => handleShowLanguage(lang.language_identifier)}

                                            icon={<BiSolidDownArrow />}
                                            background={{ normal: "", hover: "" }}
                                            border={{ normal: "", hover: "" }}
                                            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                        /> : "" }
                                    </div>
                                    }
                                    
                                    <Form 
                                        className={`content_form${showLanguage === lang.language_identifier ? ' show' : ' hide'}`}
                                        lang={lang.language_identifier}
                                        data={contentByLang[lang.language_identifier]}
                                        action="edit"
                                        loadingForm={loadingForm}
                                        setLoadingForm={setLoadingForm}
                                        success={success}
                                        contentByLang={contentByLang}
                                        setContentByLang={setContentByLang}

                                        productList={productList}
                                        setProductList={setProductList}

                                        segments={segments}
                                        setSegments={setSegments}
                                        segmentsError={segmentsError}
                                        setSegmentsError={setSegmentsError}

                                        publicationDate={publicationDate}
                                        setPublicationDate={setPublicationDate}
                                        publishDateError={publishDateError}
                                        setPublishDateError={setPublishDateError}
                                        immediatePublication={immediatePublication}
                                        setImmediatePublication={setImmediatePublication}
                                        segmentationOpen={segmentationOpen}
                                        setSegmentationOpen={setSegmentationOpen} 

                                        points={points}
                                        setPoints={setPoints}
                                        allProducts={allProducts}
                                        setAllProducts={setAllProducts}
                                        minGeneral={minGeneral}
                                        setMinGeneral={setMinGeneral}
                                        maxGeneral={maxGeneral}
                                        setMaxGeneral={setMaxGeneral}
                                        subType={subType}
                                        setSubType={setSubType}
                                        startDate={startDate}
                                        setStartDate={setStartDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                        vigencyDateError={vigencyDateError}
                                        setVigencyDateError={setVigencyDateError}
                                        timezone={timezone}
                                        setTimezone={setTimezone}
                                        
                                    />
                                    
                                </div>
                            )
                        })
                    }

                    <FormBottomMessage 
                        className="form_error_bottom"
                        haveError={haveErrorForm}
                        text={errorFormMessage.bottom}
                    />

                    <div className="actions">
                        {!loadingForm ?
                            <ButtonCustom 
                                className="bt_submit"
                                text={"Crear Beneficio"}
                                onClick={handleSubmit}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                            :
                            <div className='loader'>
                                <LoaderComponent size={25} color={panColors.white} />
                            </div>
                        }
                        <ButtonTextCustom 
                            className="bt_cancel"
                            text="Cancelar"
                            onClick={() => { !loadingForm && navigate("/benefits") }}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.red, hover: panColors.red }}
                            disabled={loadingForm}
                        />
                    </div>
                </>    
                :
                <div className='loader'>
                    <LoaderComponent />
                </div>
            }
            </>
            :
            <SuccessForm 
                image={successImage} 
                actionSuccessText={"Se ha guardado los datos del Voucher con éxito."}
                resultSuccessText={`Ahora se puede encontrar este voucher en la lista de beneficios (fecha de activación: ${immediatePublication ? dayjs().format('YYYY-MM-DD HH:mm') : dayjs(publicationDate).format('YYYY-MM-DD HH:mm')}).`}
                buttonBackToListText={"Volver a listado de beneficios"}
                buttonCreateNewText={null}
                buttonCreateContentText={null}
                buttonCreateEvaluationText={null}
                buttonBackToListStyle={"invert"}
                buttonCreateNewStyle={"invert"}
                buttonCreateContentStyle={"normal"}
                createContent={null}
                createEvaluation={null}
                createNew={null}
                backTo={"/benefits"}
                setSuccess={setSuccess}
                type={"create"}
            />
            }













            {/* {!success ? 
            <>
            {openModalImageProfile &&
                <ModalImageProfile 
                    openModalImageProfile={openModalImageProfile} 
                    setOpenModalImageProfile={setOpenModalImageProfile} 
                />
            }

            <Confirmation 
                open={confirmationOpen} 
                setOpen={setConfirmationOpen}
                type={'course'}
                data={segments}
                noSegments={noSegments}
                setNoSegments={setNoSegments}
                segments={segments}
                setSegments={setSegments}
                handleCallback={handleConfirmationCallback}
                handleSubmit={handleSubmitFinal}
                handleCancelSubmit={handleCancelSubmit}
                description={`El posteo será publicado ${immediatePublication ? dayjs().format('YYYY-MM-DD HH:mm') : dayjs(publicationDate).format('YYYY-MM-DD HH:mm')} después de confirmar y guardar de esta acción.`}
            />

            <Segmentation 
                open={segmentationOpen} 
                setOpen={setSegmentationOpen}
                segments={segments}
                setSegments={setSegments}
            />

            {benefit && Object.keys(contentByLang).length ?
                <>
                    <FormTopMessage 
                        haveError={haveErrorForm}
                        text={errorFormMessage.top}
                    />
                    
                    {Object.keys(contentByLang).length ?
                        Object.keys(contentByLang).map(function(key) {
                            return(
                                <div className={`box_lang ${Object.keys(contentByLang).length > 1 ? " multilang" : ""}`}>
                                    {Object.keys(contentByLang).length > 1 &&
                                    <div className="head_form_lang">
                                        <div className="title">
                                            <div className="icon">
                                                <LanguageIcon />
                                            </div>
                                            <div className="text">
                                                Beneficio en { key }
                                            </div>
                                        </div>
                                        <div className="bt_show">
                                            <div className="icon"></div>
                                        </div>
                                    </div>
                                    }
                                    <Form 
                                        lang={key}
                                        data={contentByLang[key]}
                                        action="edit"
                                        loadingForm={loadingForm}
                                        setLoadingForm={setLoadingForm}
                                        success={success}
                                        contentByLang={contentByLang}
                                        setContentByLang={setContentByLang}
                                        segments={segments}
                                        setSegments={setSegments}
                                        segmentsError={segmentsError}
                                        setSegmentsError={setSegmentsError}
                                        labelsData={benefit.labels}

                                        publicationDate={publicationDate}
                                        setPublicationDate={setPublicationDate}
                                        publicationDateData={benefit.publication_date}
                                        publishDateError={publishDateError}
                                        setPublishDateError={setPublishDateError}
                                        immediatePublication={immediatePublication}
                                        setImmediatePublication={setImmediatePublication}
                                        labelsArr={labelsArr}
                                        setLabelsArr={setLabelsArr}
                                        segmentationOpen={segmentationOpen}
                                        setSegmentationOpen={setSegmentationOpen} 

                                        points={points}
                                        setPoints={setPoints}
                                        stock={stock}
                                        setStock={setStock}
                                        stockData={benefit.stock}
                                        pointsData={benefit.points}
                                        
                                        startDate={startDate}
                                        setStartDate={setStartDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                        benefitType={benefitType}
                                        setBenefitType={setBenefitType}
                                        benefitTypeData={benefit.type}
                                        timezone={timezone}
                                        setTimezone={setTimezone}
                                    />
                                </div>
                            )
                        })
                        
                        :
                        <div className='loader'>
                            <LoaderComponent />
                        </div>
                    }

                    <FormBottomMessage 
                        className="form_error_bottom"
                        haveError={haveErrorForm}
                        text={errorFormMessage.bottom}
                    />

                    <div className="actions">
                        {!loadingForm ?
                            <ButtonCustom 
                                className="bt_submit"
                                text={"Guardar Beneficio"}
                                onClick={handleSubmit}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                            :
                            <div className='loader'>
                                <LoaderComponent size={25} color={panColors.white} />
                            </div>
                        }
                        <ButtonTextCustom 
                            className="bt_cancel"
                            text="Cancelar"
                            onClick={() => { !loadingForm && navigate("/benefits") }}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.red, hover: panColors.red }}
                            disabled={loadingForm}
                        />
                    </div>
                </>
                :
                <div className='loader'>
                    <LoaderComponent />
                </div>
            }

            
            </>
            :
            <SuccessForm 
                image={successImage} 
                actionSuccessText={"Se ha guardado los datos del Beneficio con éxito."}
                resultSuccessText={`Ahora se puede encontrar este beneficio en la lista de beneficios (fecha de activación: ${immediatePublication ? dayjs().format('YYYY-MM-DD HH:mm') : dayjs(publicationDate).format('YYYY-MM-DD HH:mm')}).`}
                buttonBackToListText={"Volver a listado de beneficios"}
                buttonCreateNewText={"Crear beneficio nuevo"}
                buttonCreateContentText={null}
                buttonCreateEvaluationText={null}
                buttonBackToListStyle={"invert"}
                buttonCreateNewStyle={"invert"}
                buttonCreateContentStyle={"normal"}
                createContent={null}
                createEvaluation={null}
                createNew={"/benefits/create"}
                backTo={"/benefits"}
                setSuccess={setSuccess}
                type={"create"}
            />
            }*/}

        </WrapperStyled> 
    )
                
}

export default CreateContainer;
