import React, { Fragment, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from "luxon";
import Avatar from '@mui/material/Avatar';
import LoaderComponent from '../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import DeleteElement from "../../../components/elements/Dialogs/DeleteElement";
import ActivateElement from "../../../components/elements/Dialogs/ActivateElement";
import DeactivateElement from "../../../components/elements/Dialogs/DeactivateElement";
import ResetPassword from "../../../components/elements/Dialogs/ResetPassword";
import { useTranslation } from 'react-i18next';


import { ProfileIcon, 
         AreaIcon, 
         RoleIcon,
         MobileIcon, 
         UserIcon, 
         EmailIcon,
         ExclamationIcon, 
         TimezoneIcon, 
         LenguageIcon, 
         LocalizationAreaIcon, 
         CountryIcon,
         CapIcon,
         RegionIcon,
         EyeIcon,
         EditIcon,
         UserDesactivateIcon,
         TrashIcon } from '../../../helpers/icons';

import { ContentContext,
         ConfigurationContext } from '../../../context';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .loader{
        width: 100%;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-top: 30px;
        .bt_edit,
        .bt_deactivate,
        .bt_reset,
        .bt_delete{
            margin-right: 10px;
        }
        .bt_delete{
            margin-right: 0;
        }
        .bt_reset{
            font-size: 22px;
        }
    }
    .content_user_data{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        
        >.left{
            display: flex;
            flex-direction: row;
            .user_data{
                margin-left: 15px;
                p{
                    display: flex;
                    flex-direction: row;
                    font-size: 18px;
                    line-height: 22px;
                    color: #484D63;
                    margin: 0;
                    .text{
                        margin-left: 5px;
                    }
                }
                p.name{
                    font-size: 26px;
                    line-height: 25px;
                    font-weight: 600;
                }
                p.email{
                    margin-top: 15px;
                }
                p.coid{
                    margin-top: 5px;
                }
            }
        }
        >.right{
            display: flex;
            flex-direction: row;
            .user_lastconnection,
            .user_knowledge{
                display: flex;
                flex-direction: row;
                .icon{
                    display: flex;
                    font-size: 40px;
                    color: #3A3845;
                }
                .info{
                    margin-left: 15px;
                    .title{
                        font-size: 20px;
                        line-height: 22px;
                        color: #676F8F;
                        font-weight: 600;
                    }
                    .value{
                        font-size: 18px;
                        color: #484D63;
                    }
                }
            }
            .user_knowledge{
                margin-left: 40px;
            }
        }
        
    }
    .content_general_data{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        width: 100%;
        .data_columns{
            display: flex;
            flex-direction: column;
            width: calc(33% - 15px);
            .icon{
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-right: 5px;
                width: 24px;
                svg{
                    fill: rgb(109, 105, 239);//
                }
            }
            .data_column{
                margin-bottom: 40px;
                .title{
                    font-size: 19px;
                    font-weight: 600;
                    color: #484D63;
                    margin-bottom: 20px;
                }
            }
            .data_column:last-child{
                margin-bottom: 0;
            }
            ul.elements{
                margin: 0;
                padding: 0;
                li.element{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    color: #767676;
                    font-size: 16px;
                    line-height: 19px;
                    list-style: none;
                    margin-bottom: 15px;
                    .text{
                        display: flex;
                        margin-left: 5px;
                    }
                }
                li.element:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .accordion_elements{
        margin-top: 50px;
        width: 100%;
    }
    .highlighted{
        display: flex;
        font-weight: 700;
    }
`

const ViewContainer = (props) => {
    
    const { t } = useTranslation();

    const { user, 
            setUser,
            users,
            getUser,
            basicData } = useContext(ContentContext);

    const { panColors,
            clientData } = useContext(ConfigurationContext);
            
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ titleActivateText, setTitleActivateText ] = useState("");
    const [ titleDeactivateText, setTitleDeactivateText ] = useState("");
    const [ elementToDelete, setElementToDelete ] = useState("");
    const [ elementToDeactivate, setElementToDeactivate ] = useState("");
    const [ elementToActivate, setElementToActivate ] = useState("");
    
    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ isActivated, setIsActivated ] = useState(false);
    const [ isDeactivated, setIsDeactivated ] = useState(false);
    
    const [ activateElementOpen, setActivateElementOpen ] = useState(false);
    const [ deactivateElementOpen, setDeactivateElementOpen ] = useState(false);

    const [ resetPasswordOpen, setResetPasswordOpen ] = useState(false);
    const [ elementToReset, setElementToReset ] = useState("");
    

    const navigate = useNavigate();
    const { id } = useParams();
    
    useEffect(() => {
        
        if(users === null || users === undefined){
            if(user === null || user === undefined){
                getUser(id);
            }
        }else{
            //if(id && users){
            if(id){
                const fitered = users.users.filter(user => user._id === id)[0];
                if(fitered){
                    setUser(fitered);
                }
            }
        }
        
        
      return () => {}
    }, [users, id])

    const handleDelete = (id, fullname) => {
        setElementToDelete(JSON.stringify([{_id: id}]));
        setTitleDeleteText(t("texts.Confirmar Eliminar al usuario", {description: fullname}));
        setDeleteElementOpen(true);
    }

    const handleActivateUser = (id, fullname) => {
        setElementToActivate(JSON.stringify([{_id: id}]));
        setTitleActivateText(t("texts.Confirmar Activar al usuario", {description: fullname}));
        setActivateElementOpen(true);
    }

    const handleDeactivateUser = (id, fullname) => {
        setElementToDeactivate(JSON.stringify([{_id: id}]));
        setTitleDeactivateText(t("texts.Confirmar Licenciar al usuario", {description: fullname}));
        setDeactivateElementOpen(true);
    }

    const handleClearPassword = (id) => {
        setElementToReset(id);
        setResetPasswordOpen(true);
    }

    
    
    useEffect(() => {

        if(isDeleted){
            setIsDeleted(false);
            navigate(`/users`);
        }
        return () => {}

    }, [isDeleted])

    useEffect(() => {
    
      return () => {
        setIsDeleted(false);
      }
      
    }, [])
    
    useEffect(() => {

        if(isActivated){
            const activated_update = {...user};
            activated_update.status = 0;
            setUser(activated_update)
            setIsActivated(false);
            setElementToActivate(null);
            setActivateElementOpen(false);
        }
        if(isDeactivated){
            const deactivated_update = {...user};
            deactivated_update.status = -2;
            setUser(deactivated_update);
            setIsDeactivated(false);
            setElementToDeactivate(null);
            setDeactivateElementOpen(false);
        }
        return () => {}
        
    }, [isDeactivated, isActivated])


    return (
        <WrapperStyled className="dashboard">
            
            <DeactivateElement
                open={deactivateElementOpen} 
                setOpen={setDeactivateElementOpen} 
                title={titleDeactivateText}
                setTitle={setTitleDeactivateText}
                id={elementToDeactivate}
                scope="users"
                isDeactivated={isDeactivated}
                setIsDeactivated={setIsDeactivated}
            />
 
            <ActivateElement
                open={activateElementOpen} 
                setOpen={setActivateElementOpen} 
                title={titleActivateText}
                setTitle={setTitleActivateText}
                id={elementToActivate}
                scope="users"
                isActivated={isActivated}
                setIsActivated={setIsActivated}
            />

            <DeleteElement 
                open={deleteElementOpen} 
                setOpen={setDeleteElementOpen} 
                title={titleDeleteText}
                setTitle={setTitleDeleteText}
                id={elementToDelete}
                scope="users"
                isDeleted={isDeleted}
                setIsDeleted={setIsDeleted}
            />

            <ResetPassword 
                open={resetPasswordOpen} 
                setOpen={setResetPasswordOpen} 
                title={""}
                setTitle={""}
                id={elementToReset}
                scope="users"
                isDeleted={null}
                setIsDeleted={null}
            />

            {user === null || user === undefined || !basicData?
                <div className="loader">
                    <LoaderComponent />
                </div>
            :    
                (Object.keys(user).length && basicData) ?
                    <Fragment>
                        
                        <div className="content_user_data">
                            
                            <div className="left">

                                {basicData.settings.users.section_fields.avatar.visibility ?
                                <Avatar
                                    alt="Remy Sharp"
                                    src={user.avatar}
                                    sx={{ width: 100, height: 100 }}
                                /> : ""}

                                <div className="user_data">
                                    <p className="name">{`${user.name ? user.name : ""} ${user.last_name ? user.last_name : ""}`}</p>
                                    <p className="email">
                                        <span className="highlighted">E-mail:</span> 
                                        <span className="text">{user.email ? user.email : "---"}</span>
                                    </p>
                                    {clientData.name_clients === 'one_sp' ?
                                    <p className="coid">
                                        <span className="highlighted">DNI:</span> 
                                        <span className="text">{user.dni ? user.dni : "---"}</span>
                                    </p> : ""}
                                </div>
                            </div>
                            <div className="right">
                                <div className="user_lastconnection">
                                    <div className="icon">
                                        <EyeIcon />
                                    </div>
                                    <div className="info">
                                        <div className="title">{t("texts.Ultima conexion")}</div>
                                        <div className="value">{user.last_session_user ? DateTime.fromISO(user.last_session_user).setZone("UTC").toFormat('HH:mm:ss - dd/M/yyyy') : "---"}</div>
                                    </div>
                                </div>
                                <div className="user_knowledge">
                                    <div className="icon">
                                        <CapIcon />
                                    </div>
                                    <div className="info">
                                        <div className="title">{t("texts.Nivel de conocimiento")}</div>
                                        <div className="value">---</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="actions">
                            <ButtonCustom 
                                className="bt_edit"
                                text={t("texts.Editar datos")}
                                onClick={() => navigate(`/users/edit/${user._id}`)}
                                style_type="normal"
                                
                                icon={<EditIcon />}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                            {user.status === 0 || user.status === 1 ?
                                <ButtonCustom 
                                    className="bt_deactivate"
                                    text={t("texts.Licenciar usuario")}
                                    onClick={() => handleDeactivateUser(user._id, user.name + " " + user.last_name)}
                                    style_type="invert"

                                    icon={<UserDesactivateIcon />}
                                    background={{ normal: panColors.white, hover: panColors.primary }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                />
                                :
                                <ButtonCustom 
                                    className="bt_deactivate"
                                    text={t("texts.Activar usuario")}
                                    onClick={() => handleActivateUser(user._id, user.name + " " + user.last_name)}
                                    style_type="invert"

                                    icon={<UserDesactivateIcon />}
                                    background={{ normal: panColors.white, hover: panColors.primary }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                />
                            }
                            {/*
                            <ButtonCustom 
                                className="bt_reset"
                                text="Resetear contraseña"
                                onClick={() => handleClearPassword(user._id)}
                                style_type="invert"

                                icon={<PiPasswordBold />}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                            />
                            */}
                            <ButtonCustom 
                                className="bt_delete"
                                text={t("texts.Eliminar usuario")}
                                onClick={() => handleDelete(user._id, user.name + " " + user.last_name)}
                                style_type="invert"

                                icon={<TrashIcon />}
                                background={{ normal: panColors.white, hover: panColors.red }}
                                border={{ normal: panColors.red, hover: panColors.red }}
                                textcolor={{ normal: panColors.red, hover: panColors.white }}
                            />
                            
                        </div>
      
                        <div className="content_general_data">
                            
                            <div className="data_columns">
                                <div className="data_column">
                                    <div className="title">
                                        {t("texts.Datos personales")}
                                    </div>
                                    <ul className="elements">

                                        {basicData.settings.users.section_fields.last_name.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.last_name.label}:</span> 
                                            <span className="text">{user.last_name ? user.last_name : "---"}</span>
                                        </li> : "" }
                                        
                                        {basicData.settings.users.section_fields.name.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.name.label}:</span>
                                            <span className="text">{user.name ? user.name : "---"}</span>
                                        </li> : "" }

                                        {basicData.settings.users.section_fields.gender.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.gender.label}:</span>
                                            <span className="text">{user.gender ? user.gender : "---"}</span>
                                        </li> : "" }

                                        {basicData.settings.users.section_fields.birthday.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.birthday.label}:</span> 
                                            <span className="text">{user.birthday ? user.birthday : "---"}</span>
                                        </li> : "" }
                                        
                                        {basicData.settings.users.section_fields.prefix.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.prefix.label}:</span>
                                            <span className="text">{user.prefix ? user.prefix : "---"}</span>
                                        </li> : "" }

                                        {basicData.settings.users.section_fields.dni.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <UserIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.dni.label}:</span>
                                            <span className="text">{user.dni ? user.dni : "---"}</span>
                                        </li> : "" }

                                    </ul>
                                </div>
                                <div className="data_column">
                                    <div className="title">
                                        {t("texts.Datos de contacto")}
                                    </div>
                                    <ul className="elements">

                                        {basicData.settings.users.section_fields.phone.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <MobileIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.phone.label}:</span>
                                            <span className="text">{user.phone ? user.phone : "---"}</span>
                                        </li> : ""}

                                        {basicData.settings.users.section_fields.email.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <EmailIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.email.label}:</span>
                                            <span className="text">{user.email ? user.email : "---"}</span>
                                        </li> : ""}

                                    </ul>
                                </div>
                            </div>
                            
                            

                            <div className="data_columns">
                                <div className="data_column">
                                    <div className="title">
                                        {t("texts.Datos de organizacion")}
                                    </div>
                                    <ul className="elements">
                                        <li className="element">
                                            <span className="icon">
                                                <ProfileIcon />
                                            </span>
                                            <span className="highlighted">{t("texts.Perfil")}:</span>
                                            <span className="text">{user.user_profile.name ? user.user_profile.name : "---"}</span>
                                        </li>
                                        
                                        {basicData.settings.users.section_fields.area.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <AreaIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.area.label}:</span>
                                            <span className="text">{user.area ? user.area.name : "---"}</span>
                                        </li> : ""}

                                        {basicData.settings.users.section_fields.region.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <RegionIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.region.label}:</span>
                                            <span className="text">{user.region ? user.region.name : "---"}</span>
                                        </li> : ""}
                                        
                                        {basicData.settings.users.section_fields.role.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <RoleIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.role.label}:</span>
                                            <span className="text">{user.role ? user.role.name : "---"}</span>
                                        </li> : ""}

                                    </ul>
                                </div>
                                <div className="data_column">
                                    {(basicData.settings.users.section_fields.country.visibility || basicData.settings.users.section_fields.city.visibility || basicData.settings.users.section_fields.language.visibility || basicData.settings.users.section_fields.time_zone.visibility) ? 
                                    <div className="title">
                                        {t("texts.Informacion de localizacion")}
                                    </div> : ""}

                                    <ul className="elements">

                                        {basicData.settings.users.section_fields.country.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <CountryIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.country.label}:</span>
                                            <span className="text">{user.country ? user.country.name : "---"}</span>
                                        </li> : ""}

                                        {basicData.settings.users.section_fields.city.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <LocalizationAreaIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.city.label}:</span>
                                            <span className="text">{user.city ? user.city.name : "---"}</span>
                                        </li> : ""}

                                        {basicData.settings.users.section_fields.language.visibility ?
                                        <li className="element">
                                            <span className="icon">
                                                <LenguageIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.language.label}:</span>
                                            <span className="text">{user.language ? user.language : "---"}</span>
                                        </li> : ""}
                                        
                                        {basicData.settings.users.section_fields.time_zone.visibility ? 
                                        <li className="element">
                                            <span className="icon">
                                                <TimezoneIcon />
                                            </span>
                                            <span className="highlighted">{basicData.settings.users.section_fields.time_zone.label}:</span>
                                            <span className="text">{user.time_zone ? user.time_zone : "---"}</span>
                                        </li> : ""}

                                    </ul>
                                </div>
                            </div>
                            
                            {'additional' in user && basicData.settings.users.section_fields.additional.visibility &&

                            <div className="data_columns">
                                <div className="data_column">
                                    <div className="title">
                                        {t("texts.Informacion adicional")}
                                    </div>
                                    
                                    <ul className="elements">
                                        {user.additional.length ?  
                                            user.additional.map((info, index) => {
                                                return(
                                                    <li className="element">
                                                        <span className="icon">
                                                            <ExclamationIcon />
                                                        </span>
                                                        <span className="highlighted">{`${info.label}:`}</span>
                                                        <span className="text">{typeof info.value === 'string' && info.value !== null ? info.value : "---"}</span>
                                                    </li>
                                                )
                                            })
                                        :
                                        <li className="element">
                                            <span className="icon">
                                                <ExclamationIcon />
                                            </span>
                                            <span className="highlighted">{t("texts.Info adicional")}:</span>
                                            <span className="text">- - -</span>
                                        </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                            }
                        </div>
                        
                    </Fragment>
                :
                    <>no hay datos</>
            }
        </WrapperStyled>

    )

}

export default ViewContainer;
