import React, { useContext, useEffect, useState, Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import SelectCustom from '../elements/SelectCustom';
import InputCustom from '../elements/InputCustom';
import ButtonCustom from '../elements/ButtonCustom';
import TextareaCustom from '../elements/TextareaCustom';
import ActionBox from '../elements/ActionBox';
import MultipleChoice from '../Evaluations/MultipleChoice';
import OpenAnswer from '../Evaluations/OpenAnswer';
import SuccessForm from '../elements/SuccessForm';
import ButtonTextCustom from '../elements/ButtonTextCustom';
import successImage from '../../assets/images/img_success_benefits.svg';
import LoaderComponent from '../elements/LoaderComponent';
import { BiSolidDownArrow } from "react-icons/bi";
import BoxSurveyData from './BoxSurveyData';

import { useParams } from 'react-router-dom';

import ScheduleDateTimeRange from '../elements/Dialogs/ScheduleDateTimeRange';

import { TitleIcon, 
         HeadlineIcon,
         CountIcon,
         CalendarMonthIcon,
         EditIcon,
         LanguageIcon } from '../../helpers/icons';

import { getQuestionTypeId, 
         dataObjectSurvey,
         dataObjectOpenAnswer,
         dataObjectMultipleChoice,
         dataObjectSurveyQuestionBasics,
         dataObjectEvaluationsQuestionBasics,
         getQuestionTypeTitle,
         getNameLanguage } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .question_type_container.show{
        display: flex;
        flex-direction: column;
    }
    .question_type_container.hide{
        display: none;
    }
    
    
    .question_type_container.show{
        display: flex;
        flex-direction: column;
    }
    .question_type_container.hide{
        display: none;
    }
    
    

    .box_lang{
        width: 100%;
        .question_type_container{
            padding: 35px 50px;
            box-sizing: border-box;
            background-color: ${props => `rgb(${props.pancolors.light_white})`};
            margin-top: 0;
            border-radius: 8px;
            .title{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    display: flex;
                    svg{
                        fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                    }
                }
                .text{
                    color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 600;
                    margin-left: 7px;
                }
            }
        }

        &.error{
            border: 1px solid ${props => `rgb(${props.pancolors.red})`}!important;

            .head_form_lang{
                
                >.title{
                    .icon{
                        svg{
                            fill: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                    .text{
                        color: ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        &.multilang{
            box-sizing: border-box;
            padding: 0px 30px 0px;
            background-color: ${props => `rgb(${props.pancolors.light_white})`};
            border: 1px solid ${props => `rgb(${props.pancolors.light_white})`};
            margin-bottom: 20px;
            .question_type_container{
                padding: 5px 50px;
            }
            &.open{
                padding: 0px 30px 20px;
            }
            .head_form_lang{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 20px 0 20px 0;
                
                >.title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon{
                        svg{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text{
                        font-size: 22px;
                        line-height: 22px;
                        font-weight: 700;
                        color: ${props => `rgb(${props.pancolors.dots})`};
                        margin-left: 10px;
                    }
                }
            }
        }
        &.singlelang{
            margin-bottom: 50px;
        }
    }
    

    .evaluation_questions{
        display: grid; 
        grid-template-columns: repeat(15, 1fr);
        gap: 20px 0;
        width: 100%;
        margin: 0 0 30px 0;
        .question_item{
            display: flex;
            flex-direction: row;
            align-items: center;
            .question_bullet{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                .order_number{
                    color: #FFFFFF;
                    font-size: 25px;
                }
            }
            .line{
                width: calc(100% - 50px);
                height: 2px;
                background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
            }
            &.selected{
                .question_bullet{
                    cursor: default;
                    background-color: ${props => `rgb(${props.pancolors.dots})`}!important; 
                }
            }
        }
    }
    .question_type_container{
        padding: 25px 50px 25px 50px;
        box-sizing: border-box;
        background-color: ${props => `rgb(${props.pancolors.light_white})`};
        margin-top: 20px;
        border-radius: 8px;
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon{
                display: flex;
                svg{
                    fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                }
            }
            .text{
                color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                margin-left: 7px;
            }
        }
    }



    .action_box_segmentation,
    .action_box_evaluation{
        margin-bottom: 30px;
    }
    .box_form{
        display: grid; 
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
        .field_input_type{
            margin-bottom: 20px;
        }
        .box_column{
            .action_box_evaluation{
                //margin-top: 50px;
            }
            .media_slides{
                margin-top: 30px;
                .no_multimedia_content{
                    box-sizing: border-box;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    border: 1px dashed ${props => `rgb(${props.pancolors.middle_gray})`};
                    color: ${props => `rgb(${props.pancolors.dark_gray})`};
                    font-weight: 600;
                }
            }
        }
        &.show{
            display: grid;
        }
        &.hide{
            display: none;
        }




    }
    .box_evaluation{
        &.show{
            display: flex;
        }
        &.hide{
            display: none;
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            &.file_publication_date{
                margin-bottom: 7px;
            }
            :last-child{
                margin-bottom: 0;
            }
            .datetime_activate_at{
                width: 100%;
                .MuiOutlinedInput-notchedOutline{
                    border-color: rgba(0, 0, 0, 0.23);
                }
                &.error{
                    .MuiOutlinedInput-notchedOutline{
                        border: 2px solid ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        .box_field_description{
            margin-top: 20px;
        }
        .field_timezone{
            margin-bottom: 10px;
        }
        .field_immediate_publication{
            .label_immediate_publication{
                margin-bottom: 0;
            }
        }
        .field_description_long{
            margin-bottom: 0!important;
            textarea{
                height: 160px;
            }
        }
        .field_description_short{
            margin-bottom: 0!important;
            textarea{
                height: 120px;
            }
        }
        .bottom_textarea{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            font-size: 14px;
            margin-top: 5px;
            font-weight: 700;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            &.error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .box_field_description_long,
        .box_field_description_short,
        .box_field_title{
            width: 100%;
            margin-bottom: 25px!important;

            .error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .field_send_notification{
            .label_custom{
                margin-bottom: 0;
            }
        }
        .field_test_content{
            margin-bottom: 15px;
        }
    }

    .box_labels{
        width: 100%;
        .box_add_labels{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .field_labels_add{
                width: 100%;
            }
            .bt_add_label{
                margin: 15px 0;
                padding: 0;
                height: auto;
                p{
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .box_labels_selected{
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            flex-wrap: wrap;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border: 2px solid ${props => `rgb(${props.pancolors.dark_gray})`};
                border-radius: 8px;
                box-sizing: border-box;
                padding: 10px 15px;
                margin-bottom: 10px;
                margin-right: 10px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                font-weight: 600;
                &.exist{
                    border-color: ${props => `rgb(${props.pancolors.dots})`};
                    background-color: ${props => `rgb(${props.pancolors.dots})`};
                    color: #FFFFFF;
                }
                .close{
                    cursor: pointer;
                    :hover{
                        color: ${props => `rgb(${props.pancolors.red})`};
                    }
                }
                :first-child {
                    margin-left: 0;
                }
                p{
                    margin: 0 0 0 5px;
                }
            }
        }
    }
    .box_link_destination_type{
        width: 100%;
        //margin-top: 30px;
        .field_destination_title{
            margin-top: 15px;
        }
        .field_destination_link{
            margin-top: 15px;
        }
        .field_link_destination{
            margin-top: 11px;
        }
        .field_link_goto{
            margin-top: 15px;
        }
    }
    
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 20px;
        .bt_next_question{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0 0 0 10px;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }
`

const Form = (props) => {

    const { t } = useTranslation();
    
    const { id_survey } = useParams();

    const { panColors,
            userCountrySelected
     } = useContext(ConfigurationContext);
    
    const { basicData, 
            createSurvey,
            editSurvey,
            getSurvey,
            survey,
            setSurvey } = useContext(ContentContext);

    const { lang = null,
            action,
            contentByLang, 
            setContentByLang} = props;
    
    const [ blockAction, setBlockAction ] = useState(false);
    const [ questionSelectedOrder, setQuestionSelectedOrder ] = useState(null);
    const [ questionSelected, setQuestionSelected ] = useState(null);
    const [ startDate, setStartDate ] = useState("");
    const [ endDate, setEndDate ] = useState("");
    const [ vigencyDateError, setVigencyDateError ] = useState(false);
    const [ openScheduleDateTimeRange, setOpenScheduleDateTimeRange ] = useState(false);    
    const [ questionQuantityToload, setQuestionQuantityToload ] = useState([]);
    const [ questionQuantity, setQuestionQuantity ] = useState(null);
    const [ questionsList, setQuestionsList ] = useState([]);
    const [ timezone, setTimezone ] = useState(dayjs.tz.guess());
    const [ data, setData ] = useState(null);
    const [ inputTypeError, setInputTypeError ] = useState(false);
    const [ createEvaluationStatus, setCreateEvaluationStatus ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ vigencySuccessText, setVigencySuccessText ] = useState(""); 
    const [ secondaryActionsCloned, setSecondaryActionsCloned ] = useState(null);
    const [ vigencyDataLoaded, setVigencyDataLoaded ] = useState(true);
    const [ langHaveError, setLangHaveError ] = useState([]);
    const [ langDataHaveError, setLangDataHaveError ] = useState([]);
    const [ showLanguage, setShowLanguage ] = useState(null);
    const [ showLanguageData, setShowLanguageData ] = useState(null);
    const [ dataSurveyByLang, setDataSurveyByLang ] = useState([]);
    const [ dataSurveyByLangError, setDataSurveyByLangError ] = useState([]);

    const [ evaluationTypes, setEvaluationTypes ] = useState([
        {
          "_id": "single_choice",
          "name": "Opción simple"
        },
        {
          "_id": "comments",
          "name": "Campo abierto"
        }
    ]);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    useEffect(() => {
        
        //console.log("---------> ", dayjs.tz.guess())
    
      return () => {
        setData(null);
        setQuestionsList([]);
        setQuestionSelected(null);
        setQuestionQuantity(null);
        setQuestionSelectedOrder(null);
        setSurvey(null);
      }
    }, [])
    
    
    useEffect(() => {
        
        if(userCountrySelected){
            setQuestionSelectedOrder(0);

            const arrQuestionQuantityToload = [];
            for(let i = 1; i <= 10; i++){
                arrQuestionQuantityToload.push({_id: i, name: i});
            }
            setQuestionQuantityToload(arrQuestionQuantityToload);

            if(action === "create" ){

                setData(dataObjectSurvey(null));
                let data_lang = [];
                userCountrySelected.languages.map(lang => {
                    data_lang[lang.language_identifier] =  dataObjectSurvey(null);
                })
                setDataSurveyByLang(data_lang);

            }

            if(action === "edit" ){

                getSurvey(id_survey);

            }
        }

        return () => {}

    }, [userCountrySelected]);

    useEffect(() => {
      //console.log("dataSurveyByLang", dataSurveyByLang)
    
      return () => {}
    }, [dataSurveyByLang])
    
    const quetionsCount = (survey, first_lang) => {

        if("translations" in survey){
            if(Object.keys(survey.translations).length){
                return survey.translations[first_lang].questions.length;
            }
        }
        return 0;
        
    }

    const getQuestionType = (survey) => {
        return {input_type: survey.questions[0].input_type};
    }
    const getSecondaryActionsCount = (survey, index) => {

        let count = 0;
        if("translations" in survey){
            if(Object.keys(survey.translations).length){
                Object.keys(survey.translations).map(function(key) {
                    //console.log("@@@@", survey.translations[key].questions[index].secondary_actions)
                    count = survey.translations[key].questions[index].secondary_actions.length;
                })
            }
        }else{

        }
        return count;

    }

    const findQuestionType = (data, index) => {
        
        let question_type = "";

        if("translations" in data){
            if(Object.keys(data.translations).length){
                Object.keys(data.translations).map(function(lang) {
                    if("questions" in data.translations[lang]){
                        if(data.translations[lang].questions.length){
                            //console.log("findQuestionType", data.translations[lang].questions[index].input_type);
                            question_type = data.translations[lang].questions[index].input_type;
                        }
                    }
                })
            }  
        }else{
            //console.log("-->")
            return null;
        }
        
        return question_type;

    }

    useEffect(() => {
        if(survey && basicData && userCountrySelected){

            const questions_count = quetionsCount(survey, Object.keys(survey.translations)[0]);

            const surveyCloned = cloneDeep(survey);

            let questionArray = [];
            let translations = {};
            
            let questions_arr = [];
            let question_input_type = getQuestionType(survey);

            
            let data_lang = [];
            userCountrySelected.languages.map(lang => {
                if("translations" in survey){
                    if(lang.language_identifier in survey.translations){
                        data_lang[lang.language_identifier] = dataObjectSurvey(survey.translations[lang.language_identifier]);
                    }
                }
                //let dataObjectSurvey_ = survey. lang.language_identifier
            })
            setDataSurveyByLang(data_lang);
            //console.log("data_lang", data_lang)
            

            for (let i = 0; i < questions_count; i++) {
                
                const finded_question_type = findQuestionType(surveyCloned, i);
                //console.log("finded_question_type", finded_question_type)

                const secondary_actions_count = getSecondaryActionsCount(survey, i);

                questions_arr[i] = dataObjectSurveyQuestionBasics(question_input_type, null)//{};
                questions_arr[i].translations = {}; 

                const input = getQuestionTypeTitle(basicData.questions_type, finded_question_type);//question_input_type.input_type
                //console.log("++++", basicData.questions_type, question_input_type.input_type)
                questions_arr[i].input_type.value = input;

                userCountrySelected.languages.map(lang => {
                    
                    if(lang.language_identifier in surveyCloned.translations){

                        const input_question = getQuestionTypeTitle(basicData.questions_type, surveyCloned.translations[lang.language_identifier].questions[i].input_type);
                        
                        //console.log("# # #", surveyCloned.translations[lang.language_identifier].questions[i].input_type)
                        //console.log("- - -", surveyCloned.translations[lang.language_identifier].questions[i].input_type.value)
                        
                        questions_arr[i].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(surveyCloned.translations[lang.language_identifier].questions[i], null);
                        questions_arr[i].translations[lang.language_identifier].input_type.value = input_question;

                        const secondary_actions_update = surveyCloned.translations[lang.language_identifier].questions[i].secondary_actions.map((secondary, index) => {
                            //console.log("input_question", lang.language_identifier, input_question, surveyCloned.translations[lang.language_identifier].questions[i].input_type)
                            switch (input_question) {
                                case "single_choice":
                                    return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                                    break;
                                case "comments":
                                    return dataObjectOpenAnswer(secondary);
                                    break;
                                        
                                default:
                                    break;
                            }
    
                        })

                        questions_arr[i].translations[lang.language_identifier].secondary_actions = secondary_actions_update;

                    }else{

                        questions_arr[i].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(null, null);
                        questions_arr[i].translations[lang.language_identifier].input_type.value = input;
                        let secondary_actions_update = [];

                        for (let e = 0; e < secondary_actions_count; e++) {
                            switch (input) {
                                case "single_choice":
                                    secondary_actions_update[e] = dataObjectMultipleChoice(null, "evaluation_lineal");
                                    break;
                                case "comments":
                                    secondary_actions_update[e] = dataObjectOpenAnswer(null);
                                    break;
                                        
                                default:
                                    break;
                            }
                        }

                        questions_arr[i].translations[lang.language_identifier].secondary_actions = secondary_actions_update;

                    }

                })

            }


            


            /*
            let translations_obj = {};

            //return null;

            userCountrySelected.languages.map(lang => {
                translations_obj[lang.language_identifier] = {};
                translations_obj[lang.language_identifier].questions = [];

                if(lang.language_identifier in surveyCloned.translations){

                    for (let i = 0; i < questions_count; i++) {

                        translations_obj[lang.language_identifier].questions[i] = dataObjectEvaluationsQuestionBasics(surveyCloned.translations[lang.language_identifier].questions[i], null);

                        const input = getQuestionTypeTitle(basicData.questions_type, surveyCloned.translations[lang.language_identifier].questions[i].input_type);
                        translations_obj[lang.language_identifier].questions[i].input_type.value = input;

                        const secondary_actions_update = surveyCloned.translations[lang.language_identifier].questions[i].secondary_actions.map((secondary, index) => {
                        
                            switch (input) {
                                case "single_choice":
                                    return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                                    break;
                                case "comments":
                                    return dataObjectOpenAnswer(secondary);
                                    break;
                                        
                                default:
                                    break;
                            }
    
                        })
                        
                        translations_obj[lang.language_identifier].questions[i].secondary_actions = secondary_actions_update;
                    }
                    
                }else{
                    for (let i = 0; i < questions_count; i++) {
                        translations_obj[lang.language_identifier].questions.push(dataObjectEvaluationsQuestionBasics(null, null));
                    }
                }
                
            })
            */





            /*
            const question_updated = surveyCloned.questions.map((question, index) => {

                questionArray.push(dataObjectEvaluationsQuestionBasics(question, null));
                questionArray[index].translations = {};

                const input = getQuestionTypeTitle(basicData.questions_type, question.input_type);
                questionArray[index].input_type.value = input;

                userCountrySelected.languages.map(lang => {

                    questionArray[index].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(question, null);

                    const secondary_actions_update = question.secondary_actions.map((secondary, index) => {
                        
                        switch (input) {
                            case "single_choice":
                                return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                                break;
                            case "comments":
                                return dataObjectOpenAnswer(secondary);
                                break;
                                    
                            default:
                                break;
                        }

                    })
                    questionArray[index].translations[lang.language_identifier].secondary_actions = secondary_actions_update;
                })

                return question;


            })
            */

            /*
            //question.translations = {};
            questionArray.push(dataObjectEvaluationsQuestionBasics(question, null));
            questionArray[index].translations = {};
            //questionArray =  dataObjectEvaluationsQuestionBasics(question, null);

            userCountrySelected.languages.map(lang => {
                questionArray[index].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(question, null);

                const secondary_actions_update = question.secondary_actions.map((secondary, index) => {

                    const input = getQuestionTypeTitle(basicData.questions_type, question.input_type);
                    console.log("basicData.questions_type, question.input_type", basicData.questions_type, question.input_type, input)

                    if(questionArray[index]){
                        console.log("input", input)
                        questionArray[index].input_type.value = input;
                    }
                    //questionArray[index].input_type.value = input;

                    switch (input) {
                        case "single_choice":
                            return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                            break;
                        case "comments":
                            return dataObjectOpenAnswer(secondary);
                            break;
                                
                        default:
                            break;
                    }
                    
                })

                questionArray[index].translations[lang.language_identifier].secondary_actions = secondary_actions_update;
            })
            */

            setStartDate(dayjs(survey.date_init).tz(dayjs.tz.guess()).format());
            setEndDate(dayjs(survey.date_finish).tz(dayjs.tz.guess()).format());

            //console.log("questions_arr", questions_arr)
            setQuestionsList(questions_arr);
            setQuestionSelected(questions_arr[0]);
            setQuestionQuantity(questions_arr.length);
            
            //setQuestionsList(questionArray);
            //setQuestionSelected(questionArray[0]);
            //setQuestionQuantity(questionArray.length);
            setData(dataObjectSurvey(survey));



            /*
            setQuestionQuantity(survey.questions.length);
            setData(dataObjectSurvey(survey));

            const question_arr = [];
            for(let i=0; i<survey.questions.length; i++){

                survey.questions[i].input_type = getQuestionTypeTitle(basicData.questions_type, survey.questions[i].input_type);
                let data = dataObjectEvaluationsQuestionBasics(survey.questions[i], null);

                const secondary_actions_update = survey.questions[i].secondary_actions.map((secondary, index) => {
                    switch (survey.questions[i].input_type) {
                        case "single_choice":
                            return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                            break;
                        case "comments":
                            return dataObjectOpenAnswer(secondary);
                            break;
                                
                        default:
                            break;
                    }
                    
                })

                setStartDate(dayjs(survey.date_init).tz(dayjs.tz.guess()).format());
                setEndDate(dayjs(survey.date_finish).tz(dayjs.tz.guess()).format());
                setSecondaryActionsCloned(cloneDeep(secondary_actions_update));

                data.secondary_actions = secondary_actions_update;
                //console.log("data", data)
                question_arr.push(data);

            }
            
            setQuestionsList(question_arr);
            setQuestionSelected(question_arr[0])
            */





        }
    
      return () => {}
    }, [survey, basicData, userCountrySelected])

    useEffect(() => {

        if(action === "create"){
            setVigencyDataLoaded(false)
        }
        if(action === "edit"){
            if(startDate.length && endDate.length){
                //setVigencyDataLoaded(false)
            }
        }
    
      return () => {}
    }, [startDate, endDate])
    
    

    const handlePrev = () => {
        setCreateEvaluationStatus(false);
        const prev_question = questionSelectedOrder - 1;
        setQuestionSelectedOrder(prev_question);
        setQuestionSelected(questionsList[prev_question]);
    }

    const handleNext = async (isConfirmation = false) => {
        //console.log("validated")
        const validated = await goNext(isConfirmation);
        
        /*
        if(!isConfirmation){
            setCreateEvaluationStatus(false);
            if(questionSelectedOrder < (questionsList.length - 1)){
                const next_question = questionSelectedOrder + 1;
                setQuestionSelectedOrder(next_question);
                setQuestionSelected(questionsList[next_question]);
            }
        }else{
            handleSendSurvey();
            setCreateEvaluationStatus(true);
        }
        */
    }





    const goNext = (isConfirmation = false) => {
        //return new Promise((resolve, reject) => {
            setCreateEvaluationStatus(false);
            const questionSelected_to_validate = {...questionSelected};
        
            let langsError = [];
            let langsDataError = [];
            let haveError = false;
            let haveMediaQuestion = false;
            let haveMediaAnswer = 0;
            
            Object.keys(questionSelected_to_validate).map(function(key) {

                if(key !== "translations"){
                    if(questionSelected_to_validate[key].required){
                        if(!questionSelected_to_validate[key].value){
                            questionSelected_to_validate[key].error = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate[key].error = false;
                        }
                    }
                }
                
                if(key === "translations"){
                    if(questionSelected_to_validate.input_type.value === "single_choice"){
                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){

                                    questionSelected.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(!secondary[sakey].value.length){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                        return secondary;
                                    })

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })

                    }

                    if(questionSelected_to_validate.input_type.value === "comments"){

                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){
                                    
                                    if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].length){
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = true;
                                        lang_error = true;
                                        haveError = true;
                                    }else{
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = false;
                                    }

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }
                            
                        })
                    }

                }
                

            })

            //console.log("questionSelected_to_validate", haveError, questionSelected_to_validate)
            
            setQuestionSelected(questionSelected_to_validate);

            if(!haveError){

                if(!isConfirmation){
                    setCreateEvaluationStatus(false);
                    if(questionSelectedOrder < (questionsList.length - 1)){
                        const next_question = questionSelectedOrder + 1;
                        setQuestionSelectedOrder(next_question);
                        setQuestionSelected(questionsList[next_question]);
                    }
                }else{
                    handleSendSurvey();
                    setCreateEvaluationStatus(true);
                }

                /*
                const question_ok = data.questions.map((question, index) => {
                    if((questionSelectedOrder) === index){
                        return questionSelected_to_validate;
                    }else{
                        return question;
                    }
                })
                
                let contentByLangUpdate = {...contentByLang};
                contentByLangUpdate.questions = question_ok;
                setContentByLang(contentByLangUpdate);
                */
                
                //resolve({status: true, haveMediaQuestion: haveMediaQuestion, haveMediaAnswer: haveMediaAnswer});
            }else{
                //alert("else");
                setLangDataHaveError(langsDataError);
                setLangHaveError(langsError);
                setCreateEvaluationStatus(false);
                //reject({status: false});//
                //reject(true);//"Failure: The promise has failed!"
            }
            
        //})
        
    }



    /*
    const goNext = (isConfirmation = false) => {
        return new Promise((resolve, reject) => {
            setCreateEvaluationStatus(false);
            const questionSelected_to_validate = {...questionSelected};

            let langsError = [];
            let haveError = false;
            let haveMediaQuestion = false;
            let haveMediaAnswer = 0;
                
            Object.keys(questionSelected_to_validate).map(function(key) {

                if(key !== "translations"){
                    if(questionSelected_to_validate[key].required){
                        if(!questionSelected_to_validate[key].value){
                            questionSelected_to_validate[key].error = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate[key].error = false;
                        }
                    }
                }
                
                if(key === "translations"){
                    if(questionSelected_to_validate.input_type.value === "single_choice"){
                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){

                                    questionSelected.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(!secondary[sakey].value.length){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                        return secondary;
                                    })

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })

                    }

                    if(questionSelected_to_validate.input_type.value === "comments"){

                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){
                                    
                                    if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].length){
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = true;
                                        lang_error = true;
                                        haveError = true;
                                    }else{
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = false;
                                    }

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }
                            
                        })
                    }

                }
                

            })

            
            
            setQuestionSelected(questionSelected_to_validate);

            if(!haveError){
                
                const question_ok = data.questions.map((question, index) => {
                    if((questionSelectedOrder) === index){
                        return questionSelected_to_validate;
                    }else{
                        return question;
                    }
                })
                
                let contentByLangUpdate = {...contentByLang};
                contentByLangUpdate.questions = question_ok;
                setContentByLang(contentByLangUpdate);
                
                
                resolve({status: true, haveMediaQuestion: haveMediaQuestion, haveMediaAnswer: haveMediaAnswer});
            }else{
                setLangHaveError(langsError);
                setCreateEvaluationStatus(false);
                //reject({status: false});//
                reject(true);//"Failure: The promise has failed!"
            }
            
        })
        
    }
    */



    /*
    const goNext = (isConfirmation = false) => {
        return new Promise((resolve, reject) => {
            //alert("goNext")
            setCreateEvaluationStatus(false);
            const questionSelected_to_validate = {...questionSelected};

            let haveError = false;
            let haveMediaQuestion = false;
            let haveMediaAnswer = 0;
            
            console.log("questionSelected_to_validate", questionSelected_to_validate);

            Object.keys(questionSelected_to_validate).map(function(key) {
                if(key === "penalty_score" || key === "score"){
                    return null;
                }
                if(key !== "secondary_actions"){
                    if(questionSelected_to_validate[key].required){
                        if(!questionSelected_to_validate[key].value){
                            questionSelected_to_validate[key].error = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate[key].error = false;
                        }
                    }
                }
                
                if(questionSelected_to_validate.input_type.value === "single_choice"){
                    if(key === "secondary_actions"){
                        questionSelected_to_validate[key].map((secondary, index) => {
                            Object.keys(secondary).map(function(sakey) {
                                if(secondary[sakey].required){
                                    if(!secondary[sakey].value.length){
                                        secondary[sakey].error = true;
                                        haveError = true;
                                    }else{
                                        secondary[sakey].error = false;
                                    }
                                }
                            })
                            return secondary;
                        })
                    }
                }

                if(questionSelected_to_validate.input_type.value === "comments"){
                    if(key === "secondary_actions"){
                        if(!questionSelected_to_validate[key].length){
                            questionSelected_to_validate["secondary_actions_error"] = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate["secondary_actions_error"] = false;
                        }
                    }
                }

            })
            
            setQuestionSelected(questionSelected_to_validate);

            if(!haveError){
                const question_ok = questionsList.map((q, index) => {
                    if(questionSelectedOrder === index){
                        q = questionSelected_to_validate;
                    }
                    return q;
                })
                setQuestionsList(question_ok);
                
                resolve({status: true, haveMediaQuestion: haveMediaQuestion, haveMediaAnswer: haveMediaAnswer});
            }else{
                setCreateEvaluationStatus(false);
                reject({status: false});
            }
            
        })
        
    }
    */
    
    

    const handleFormElementChange = (name, value) => {

        let updateData = {...data};
        
        switch (name) {
            case 'name':
                updateData[name].value = value;
                break;
            case 'description':
                updateData[name].value = value;
                break;
            case 'timezone':
                setTimezone(value);
                break;
            case 'question_quantity_toload':

                const question_arr = [];
                for(let i=0; i<value; i++){
                    question_arr.push(dataObjectSurveyQuestionBasics(null, null));//dataObjectSurveyQuestionBasics //dataObjectEvaluationsQuestionBasics
                }
                setQuestionsList(question_arr);
                setQuestionQuantity(value);
                break;
            case 'input_type':

                //console.log("questionsList", questionsList, questionSelectedOrder, userCountrySelected);



                /*

                questions_arr[i].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(surveyCloned.translations[lang.language_identifier].questions[i], null);
                        questions_arr[i].translations[lang.language_identifier].input_type.value = input;

                        const secondary_actions_update = surveyCloned.translations[lang.language_identifier].questions[i].secondary_actions.map((secondary, index) => {
                        
                            switch (input) {
                                case "single_choice":
                                    return dataObjectMultipleChoice(secondary, "evaluation_lineal");
                                    break;
                                case "comments":
                                    return dataObjectOpenAnswer(secondary);
                                    break;
                                        
                                default:
                                    break;
                            }
    
                        })

                        questions_arr[i].translations[lang.language_identifier].secondary_actions = secondary_actions_update;

                */



                const type = "evaluation_lineal";
                const update_input_type = questionsList.map((q, i) => {
                    //console.log("q", q);
                    if(i === questionSelectedOrder){
                        q.input_type.value = value;
                        //q.input_type = dataObjectSurveyQuestionBasics(q.input_type, null)//{};
                        q.translations = {};
                        userCountrySelected.languages.map(lang => {
                            q.translations[lang.language_identifier] = q.translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(null, null);

                            if(value === "single_choice"){
                                q.translations[lang.language_identifier]["secondary_actions"] = [dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type)];
                            }

                            if(value === "comments"){
                                q.translations[lang.language_identifier]["secondary_actions_error"] = false;
                                q.translations[lang.language_identifier]["secondary_actions"] = [];
                            }

                            q.translations[lang.language_identifier].input_type.value = value;

                        })

                        setQuestionSelected(q);
                    }
                    return q;

                })

                //console.log("update_input_type", update_input_type);

                setQuestionsList(update_input_type);
                
                //return null;

                /*
                const type = "evaluation_lineal";
                const update_input_type = questionsList.map((q, i) => {
                    if(i === questionSelectedOrder){

                        userCountrySelected.languages.map(lang => {
                            //questionArray[index].translations[lang.language_identifier] = dataObjectEvaluationsQuestionBasics(question, null);
                            const questionSelectedUpdate = {...q};
                            questionSelectedUpdate.translations[lang.language_identifier].title.value = "";
                            if(value === "single_choice"){
                                questionSelectedUpdate.translations[lang.language_identifier]["secondary_actions"] = [dataObjectMultipleChoice(null, type), dataObjectMultipleChoice(null, type)];
                            }

                            if(value === "comments"){
                                questionSelectedUpdate.translations[lang.language_identifier]["secondary_actions_error"] = false;
                                questionSelectedUpdate.translations[lang.language_identifier]["secondary_actions"] = [];
                            }
                            questionSelectedUpdate.input_type.value = value;

                            setQuestionSelected(questionSelectedUpdate);
                            q = questionSelectedUpdate;
                        
                        }) 
                        
                        //q.input_type.value = value;

                    }
                    return q;
                })

                setQuestionsList(update_input_type);
                */

                break;
            default:
                //updateData[name].value = value;
                break;
        }
        
        setData(updateData);
        
        return null;
    }

    const handleSendSurvey = () => {
        
        setBlockAction(true);
        
        let haveError = false;
        let updateData = {...data};
        
        if(!startDate && !dayjs(startDate).isValid()){
            setVigencyDateError(true);
            //console.log("1")
            haveError = true;
        }

        if(!endDate && !dayjs(endDate).isValid()){
            //console.log("2")
            setVigencyDateError(true);
            haveError = true;
        }

        if(dayjs(startDate).isValid() && dayjs(endDate).isValid()){
            setVigencyDateError(false);
        }
        /*
        if(!data.name.value.length){
            console.log("3")
            updateData.name.error = true;
            haveError = true;
        }else{
            updateData.name.error = false;
        }   
        */
        setData(updateData);

        let updateDataSurveyByLang = {...dataSurveyByLang};
        let dataSurveyByLangError_arr = [];

        Object.keys(updateDataSurveyByLang).map(lang => {
            Object.keys(updateDataSurveyByLang[lang]).map(key => {
                if(updateDataSurveyByLang[lang][key].required){
                    if(!updateDataSurveyByLang[lang][key].value.length){
                        updateDataSurveyByLang[lang][key].error = true;
                        //console.log("4", lang, key)
                        haveError = true;
                        if(!dataSurveyByLangError_arr.includes(lang)){
                            dataSurveyByLangError_arr.push(lang);
                        }
                    }else{
                        updateDataSurveyByLang[lang][key].error = false;
                    }
                }
            })
        })

        setDataSurveyByLangError(dataSurveyByLangError_arr);
        setDataSurveyByLang(updateDataSurveyByLang);
        //console.log("haveError", haveError)
        //return null;

        if(!haveError){

            let dataToSend = {};
            /*
            if(key === "translations"){

                userCountrySelected.languages.map((lang__, index) => {
                    if(_dataCloned[key][lang__.language_identifier]){

                            _dataCloned[key][lang__.language_identifier].questions.map((question, index) => {

                                Object.keys(question).map(function(key_question) {
                                    if(key_question !== 'secondary_actions'){
                                        if(key_question === 'input_type'){
                                            question[key_question] = getQuestionTypeId(basicData.questions_type, question[key_question].value);
                                        }
                                        if(key_question !== 'input_type'){
                                            question[key_question] = question[key_question].value ? question[key_question].value : "";
                                        }
                                    }
                                    if(key_question === 'secondary_actions'){
                                        question[key_question].map((answer, n) => {
                                            Object.keys(answer).map(function(key_answer) {
                                                answer[key_answer] =  answer[key_answer].value ? answer[key_answer].value : "";
                                            })
                                        })
                                    }
                                })

                            })
                        
                    }else{
                    }
                })

                userCountrySelected.languages.map((lang__, index) => {
                    if(!_dataCloned[key][lang__.language_identifier]){

                        _dataCloned[key][lang__.language_identifier] = {questions: []};
                        _dataCloned[key][lang__.language_identifier].questions = getQuestionByLang(contentByLang.questions, lang__.language_identifier);

                    }
                })
            }

            */

            setLoadingForm(true);
            //console.log("cloneDeep",  questionsList)
            const _dataCloned = cloneDeep(questionsList);
            
            dataToSend.translations = {};

            userCountrySelected.languages.map((lang, index) => {
                dataToSend.translations[lang.language_identifier] = {};
                dataToSend.translations[lang.language_identifier].questions = [];
                _dataCloned.map((question, i) => {
                    
                    

                    let question_edit = question.translations[lang.language_identifier];

                    

                    delete question_edit.id_module;
                    delete question_edit.score;
                    delete question_edit.penalty_score;
                    delete question_edit.description;

                    Object.keys(question_edit).map(function(key) {
                        //console.log("key", key)

                        if(key !== 'secondary_actions'){
                            //question_edit[key] = question_edit[key].value;
                            if(key === 'input_type'){
                                question_edit[key] = getQuestionTypeId(basicData.questions_type, question_edit[key].value);
                                //question[key_question] = getQuestionTypeId(basicData.questions_type, question[key_question].value);
                            }
                            if(key !== 'input_type'){
                                question_edit[key] = question_edit[key].value;
                                //question[key_question] = question[key_question].value ? question[key_question].value : "";
                            }
                        }

                        if(key === 'secondary_actions'){
                            question_edit[key].map((answer, n) => {

                                delete answer.correct;
                                delete answer.finish;
                                delete answer.go_to;
                                delete answer.media;

                                Object.keys(answer).map(function(key_answer) {
                                    
                                    answer[key_answer] =  answer[key_answer].value ? answer[key_answer].value : "";

                                })
                            })
                        }

                    })
                    

                    dataToSend.translations[lang.language_identifier].questions.push(question_edit);//question.translations[lang.language_identifier
                })

                dataToSend.translations[lang.language_identifier].name = lang.language_identifier in dataSurveyByLang ? dataSurveyByLang[lang.language_identifier].name.value : "";
                dataToSend.translations[lang.language_identifier].description = lang.language_identifier in dataSurveyByLang ? dataSurveyByLang[lang.language_identifier].description.value : "";

            })

            

            let payload = {};

            let _date_finish = "";
            let _date_init = "";
            
            //console.log("dataToSend", dataToSend);
            payload.translations = JSON.stringify(dataToSend.translations);//JSON.stringify(_dataCloned);//dataToSend.translations

            if(dayjs.tz.guess() === timezone){
                _date_finish = dayjs(dayjs(endDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
                _date_init = dayjs(dayjs(startDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            }else{
                _date_finish = dayjs.tz(dayjs(endDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
                _date_init = dayjs.tz(dayjs(startDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            }

            payload.date_init = _date_init;
            payload.date_finish = _date_finish;
            //payload.name = data.name.value;
            //payload.description = data.description.value;
            
            //console.log("🚀", action, payload)
            //return null;

            if(action === "edit"){

                setVigencySuccessText(t("texts.Vigencia establecida del", {from: dayjs(startDate).format('YYYY-MM-DD HH:mm') ? dayjs(startDate).format('YYYY-MM-DD HH:mm') : "...", to: dayjs(endDate).format('YYYY-MM-DD HH:mm') ? dayjs(endDate).format('YYYY-MM-DD HH:mm') : "..."}));
                
                payload.survey_id = data._id.value;
                //console.log("payload", payload);
                //return null;
                const response = editSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }
            //console.log("action", action)
            if(action === "create"){

                setVigencySuccessText(t("texts.Vigencia establecida del", {from: dayjs(startDate).format('YYYY-MM-DD HH:mm') ? dayjs(startDate).format('YYYY-MM-DD HH:mm') : "...", to: dayjs(endDate).format('YYYY-MM-DD HH:mm') ? dayjs(endDate).format('YYYY-MM-DD HH:mm') : "..."}));
                //setVigencySuccessText(`(Vigencia establecida del: ${dayjs(startDate).format('YYYY-MM-DD HH:mm')} al ${dayjs(endDate).format('YYYY-MM-DD HH:mm')})`);
                
                payload.id_post = "";

                //console.log("payload", payload);
                //return null;

                const response = createSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }

            return null;
            
            /*
            const _dataCloned_updated = _dataCloned.map((el, i) => {
                console.log("el", el)
                delete el.id_module;
                delete el.score;
                delete el.penalty_score;
                delete el.description;
                //console.log("_dataCloned", _dataCloned)
                //return null;
                

                if(Object.keys(el).length){

                    Object.keys(el).map(function(key) {
                        //console.log("key", el[key])
                        if(key === "translations"){

                            userCountrySelected.languages.map((lang__, index) => {
                                if(_dataCloned[key][lang__.language_identifier]){
    
                                    _dataCloned[key][lang__.language_identifier].questions.map((question, index) => {

                                    })
                                }
                            })

                            userCountrySelected.languages.map((lang, index) => {
                                console.log("----- ", _dataCloned[i][key][lang.language_identifier])
                                dataToSend.translations[lang.language_identifier] = {};
                                dataToSend.translations[lang.language_identifier].questions = [];
                                dataToSend.translations[lang.language_identifier].questions.push(_dataCloned[i][key][lang.language_identifier]);
                                //_dataCloned[i][key][lang.language_identifier].questions = [];
                                //console.log("el", el[key][lang.language_identifier], _dataCloned[i][key][lang.language_identifier])
                            })
                            
                        }
                    })
                    

                    
                    // Object.keys(el).map(function(key) {
                        
                    //     console.log("key", key)
                        
                    //     if(key === "translations"){

                    //         userCountrySelected.languages.map((lang, index) => {
                    //             console.log("--> ", key, _dataCloned[key])
                                
                    //             //if(_dataCloned[key][lang.language_identifier]){
                    //             //    console.log("--> ", _dataCloned[key][lang.language_identifier])
                    //             //}
                                
                    //         })
                    //     }
                        

                    //     if(key !== "secondary_actions"){
                    //         if(key === "input_type"){
                    //             el[key] = getQuestionTypeId(basicData.questions_type, el[key].value);
                    //         }else{
                    //             el[key] = el[key].value;
                    //         }
                    //     }else{
                    //         el.secondary_actions.map((secondary_action, i) => {
                    //             delete secondary_action.correct;
                    //             delete secondary_action.finish;
                    //             delete secondary_action.go_to;
                    //             delete secondary_action.media;
                    //             Object.keys(secondary_action).map(function(sakey) {
                    //                 secondary_action[sakey] = secondary_action[sakey].value;
                    //             })
                    //         })
                    //     }
                    // })
                    
                }

                return el;
            })

            console.log("🚀", dataToSend)

            let payload = {};

            let _date_finish = "";
            let _date_init = "";

            payload.questions = JSON.stringify(_dataCloned);

            if(dayjs.tz.guess() === timezone){
                _date_finish = dayjs(dayjs(endDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
                _date_init = dayjs(dayjs(startDate).format('YYYY-MM-DD HH:mm')).utc().toISOString();
            }else{
                _date_finish = dayjs.tz(dayjs(endDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
                _date_init = dayjs.tz(dayjs(startDate).format('YYYY-MM-DD HH:mm'), timezone).utc().toISOString();
            }

            payload.date_init = _date_init;
            payload.date_finish = _date_finish;
            payload.name = data.name.value;
            payload.description = data.description.value;
            
            
            if(action === "create"){

                setVigencySuccessText(`(Vigencia establecida del: ${dayjs(startDate).format('YYYY-MM-DD HH:mm')} al ${dayjs(endDate).format('YYYY-MM-DD HH:mm')})`);
                
                payload.id_post = "";

                console.log("payload", payload);
                return null;

                const response = createSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }
            if(action === "edit"){

                setVigencySuccessText(`(Vigencia establecida del: ${dayjs(startDate).format('YYYY-MM-DD HH:mm')} al ${dayjs(endDate).format('YYYY-MM-DD HH:mm')})`);
                
                payload.survey_id = data._id.value;
                console.log("payload", payload);
                return null;
                const response = editSurvey(payload);
                response.then((res) => {
                    setBlockAction(false);
                    setSuccess(true);

                    setQuestionSelectedOrder(0);
                    setQuestionSelected(null);
                    setStartDate("");
                    setEndDate("");
                    setVigencyDateError(false);
                    setQuestionQuantity(null);
                    setQuestionsList([]);
                    setData(dataObjectSurvey(null));
                    setCreateEvaluationStatus(false);
                    setLoadingForm(false);

                })

            }
            */

        }else{
            setBlockAction(false);
        }
    }

    const handleSaveVigency = () => {
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    const handleVigencyRange = () => {
        setVigencyDataLoaded(false);
        setOpenScheduleDateTimeRange(!openScheduleDateTimeRange);
    }

    const handleShowLanguage = (lang) => {
        if(lang !== showLanguage){
            setShowLanguage(lang);
        }else{
            setShowLanguage(null);
        }
    }

    const handleShowLanguageData = (lang) => {
        if(lang !== showLanguageData){
            setShowLanguageData(lang);
        }else{
            setShowLanguageData(null);
        }
    }

    useEffect(() => {
        console.log("userCountrySelected", userCountrySelected)
        if(userCountrySelected){
            setShowLanguage(userCountrySelected.languages[0].language_identifier);
        }
    return () => {}
    }, [userCountrySelected])

    useEffect(() => {
        //console.log("🚀🚀🚀", dataSurveyByLangError);
    return () => {}
    }, [dataSurveyByLangError])
    

    return (
        <WrapperStyled pancolors={panColors}>

            {!success ?
                <Fragment>
                    {!vigencyDataLoaded ? 
                    <ScheduleDateTimeRange 
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate} 
                        open={openScheduleDateTimeRange} 
                        setOpen={setOpenScheduleDateTimeRange}
                        handleSubmit={handleSaveVigency}
                        timezone={timezone}
                        setTimezone={setTimezone}
                    /> : ""}
                    
                    {data ?
                    <div className="box_form">
                        <div className="box_column">
                            <div className="box_fields">

                                <ActionBox 
                                    className="action_box_segmentation"
                                    icon={<CalendarMonthIcon />}
                                    title={t("texts.Vigencia de la encuesta") + " *"}
                                    actionButtonText={t("texts.Editar Vigencia")}
                                    helpText={t("texts.Vigencia establecida del", {from: dayjs(startDate).format('YYYY-MM-DD HH:mm') ? dayjs(startDate).format('YYYY-MM-DD HH:mm') : "...", to: dayjs(endDate).format('YYYY-MM-DD HH:mm') ? dayjs(endDate).format('YYYY-MM-DD HH:mm') : "..."})}
                                    onClick={handleVigencyRange}
                                    error={vigencyDateError}
                                /> 

                                <div className="title">
                                    Datos de la encuesta
                                </div>

                                {userCountrySelected?.languages.map((lang, index) => {
                                    return(
                                        <div className={`box_lang ${userCountrySelected?.languages?.length > 1 ? " multilang" : " singlelang"}${(dataSurveyByLangError.includes(lang.language_identifier) ? ' error' : '')}${showLanguageData === lang.language_identifier ? ' open' : ''}`}>
                                            {userCountrySelected?.languages?.length > 1 &&
                                            <div className="head_form_lang">
                                                <div className="title">
                                                    <div className="icon">
                                                        <LanguageIcon />
                                                    </div>
                                                    <div className="text">
                                                        {t("texts.Encuesta en")} { getNameLanguage(userCountrySelected?.languages, lang.language_identifier) }
                                                    </div>
                                                </div>
                                                {userCountrySelected?.languages.length ?
                                                <ButtonTextCustom 
                                                    className="bt_show_more_info"
                                                    text={`${showLanguageData === lang.language_identifier ? t("texts.Cerrar") : t("texts.Abrir")}`}
                                                    onClick={() => handleShowLanguageData(lang.language_identifier)}

                                                    icon={<BiSolidDownArrow />}
                                                    background={{ normal: "", hover: "" }}
                                                    border={{ normal: "", hover: "" }}
                                                    textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                                /> : "" }
                                            </div>
                                            }
                                            <Fragment>
                                                <BoxSurveyData 
                                                    lang={lang}
                                                    showLanguageData={userCountrySelected?.languages?.length === 1 ? userCountrySelected.languages[0].language_identifier : showLanguageData}
                                                    dataSurveyByLang={dataSurveyByLang}
                                                    setDataSurveyByLang={setDataSurveyByLang}
                                                    dataSurveyByLangError={dataSurveyByLangError}
                                                />
                                            </Fragment>
                                        </div>
                                    )
                                })}

                                {/* <div className="field box_field_name">
                                    <InputCustom 
                                        name={data?.name?.ref}
                                        label={data?.name?.label}
                                        placeholder={data?.name?.placeholder}
                                        onChange={handleFormElementChange}
                                        onKeyDown={null}
                                        className={`field field_${data?.name?.ref}${data?.name?.error ? " error" : ""}`}
                                        type="text"
                                        value={data?.name?.value}
                                        help={""}
                                        isRequired={data?.name?.required}
                                        styles={ formStyle }
                                        label_icon={<TitleIcon />}
                                        tooltip_content={data?.name?.tooltip}
                                        haveError={data?.name?.error}
                                    />
                                </div>
                                <div className="field box_field_description">
                                    <TextareaCustom 
                                        name={data?.description?.ref}
                                        label={data?.description?.label}
                                        placeholder={data?.description?.placeholder}
                                        onChange={handleFormElementChange}
                                        onKeyDown={null}
                                        className={`field field_${data?.description?.ref}${data?.description?.error ? " error" : ""}`}
                                        type="text"
                                        value={data?.description?.value}
                                        help={""}
                                        isRequired={data?.description?.required}
                                        styles={ formStyle }
                                        label_icon={<HeadlineIcon />}
                                        tooltip_content={data?.description?.tooltip}
                                        haveError={data?.description?.error}
                                    />
                                </div> */}

                                
                                {action === "create" ? 
                                <SelectCustom 
                                    name={"question_quantity_toload"}
                                    label={t("texts.Cantidad de preguntas a cargar")}
                                    placeholder={t("texts.Seleccionar numero de preguntas a cargar") + "..."}
                                    onChange={handleFormElementChange}
                                    className={`field field_question_quantity_toload${(questionQuantity !== null && questionQuantity.length === 0) ? " error" : ""}`}
                                    type="text"
                                    value={questionQuantity}
                                    help={""}
                                    isRequired={true}
                                    disabled={false}
                                    styles={ formStyle }
                                    label_icon={<CountIcon />}
                                    tooltip_content={""}
                                    haveError={(questionQuantity !== null && questionQuantity.length === 0) ? true : false}
                                    data={questionQuantityToload}
                                /> : ""}

                            </div>
                        </div>



                        <div className='evaluation_questions'>

                            {questionsList.map((item, index) => {
                                let order = index;
                                return(
                                    <div key={uuid()} className={`question_item ${questionSelectedOrder === order ? " selected" : ""}`}>
                                        <div className="question_bullet">
                                            <div className="order_number">{ order + 1 }</div>
                                        </div>
                                        {index === 0 || index % (questionsList.length-1) !== 0 ?
                                            index !== (questionsList.length-1) ?
                                            <div className="line"></div>
                                            : "" : ""
                                        }
                                    </div>
                                )
                            })}
                            
                        </div>

                        {questionsList.length ?
                            <SelectCustom 
                                name={"input_type"}
                                label={t("texts.Tipo de Pregunta")}
                                placeholder={t("texts.Seleccionar tipo de pregunta") + "..."}
                                onChange={handleFormElementChange}
                                className={`field field_input_type${inputTypeError ? " error" : ""}`}
                                type="text"
                                value={questionsList[questionSelectedOrder].input_type.value}
                                help=""
                                isRequired={true}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={inputTypeError}
                                data={evaluationTypes}
                            /> : ""
                        }








                        {questionsList[questionSelectedOrder]?.input_type.value ?
                            userCountrySelected?.languages.map((lang, index) => {

                                return(
                                    <div className={`box_lang ${userCountrySelected?.languages?.length > 1 ? " multilang" : ""}${(langHaveError.filter(langerror => langerror === lang.language_identifier).length ? ' error' : '')}${showLanguage === lang.language_identifier ? ' open' : ''}`}>
                                        {userCountrySelected?.languages?.length > 1 &&
                                        <div className="head_form_lang">
                                            <div className="title">
                                                <div className="icon">
                                                    <LanguageIcon />
                                                </div>
                                                <div className="text">
                                                    {t("texts.Encuesta en")} { getNameLanguage(userCountrySelected?.languages, lang.language_identifier) }
                                                </div>
                                            </div>
                                            {userCountrySelected?.languages.length ?
                                            <ButtonTextCustom 
                                                className="bt_show_more_info"
                                                text={`${showLanguage === lang.language_identifier ? 'Cerrar' : 'Abrir'}`}
                                                onClick={() => handleShowLanguage(lang.language_identifier)}

                                                icon={<BiSolidDownArrow />}
                                                background={{ normal: "", hover: "" }}
                                                border={{ normal: "", hover: "" }}
                                                textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                            /> : "" }
                                        </div>
                                        }

                                        
                                        <Fragment>
                                            <div className={`question_type_container content_form${showLanguage === lang.language_identifier ? ' show' : ' hide'}`}>
                                                <div className="title">
                                                    <div className="icon">
                                                        <EditIcon />
                                                    </div>
                                                    <div className="text">
                                                        {t("texts.Editar pregunta")} {questionSelectedOrder+1}
                                                    </div>
                                                </div>
                                                
                                                {questionsList[questionSelectedOrder]?.input_type.value === "single_choice" &&
                                                    <MultipleChoice 
                                                        data={null}    
                                                        questionSelected={questionSelected}
                                                        setQuestionSelected={setQuestionSelected}
                                                        questionSelectedOrder={questionSelectedOrder}
                                                        contentByLang={null} 
                                                        setContentByLang={null}
                                                        lang={lang.language_identifier}
                                                        questions={null} 
                                                        imageReference={false}
                                                        response_multiple={false}
                                                        blockAction={blockAction}
                                                        setBlockAction={setBlockAction}
                                                        scope={"survey"}
                                                        disabled={action === "edit" ? true : false}
                                                    />
                                                }

                                                {questionsList[questionSelectedOrder]?.input_type.value === "comments" &&
                                                    <OpenAnswer 
                                                        data={null}
                                                        questionSelected={questionSelected}
                                                        setQuestionSelected={setQuestionSelected}
                                                        questionSelectedOrder={questionSelectedOrder}
                                                        contentByLang={contentByLang} 
                                                        setContentByLang={setContentByLang}
                                                        lang={lang.language_identifier}
                                                        //data={[dataObjectOpenAnswer()]}
                                                        questions={null} 
                                                        blockAction={blockAction}
                                                        setBlockAction={setBlockAction}
                                                        scope={"survey"}
                                                        disabled={action === "edit" ? true : false}
                                                    />
                                                }

                                            </div> 

                                        
                                        </Fragment>

                                    </div>)
                            })
                            : ""
                        }
                            















                            {/* {questionsList[questionSelectedOrder]?.input_type.value ?
                            <Fragment>
                            <div className="question_type_container">
                                <div className="title">
                                    <div className="icon">
                                        <EditIcon />
                                    </div>
                                    <div className="text">
                                        Editar pregunta {questionSelectedOrder+1}
                                    </div>
                                </div>
                                
                                {questionsList[questionSelectedOrder]?.input_type.value === "single_choice" &&
                                    <MultipleChoice 
                                        data={null}    
                                        questionSelected={questionSelected}
                                        setQuestionSelected={setQuestionSelected}
                                        questionSelectedOrder={questionSelectedOrder}
                                        contentByLang={null} 
                                        setContentByLang={null}
                                        lang={null}
                                        questions={null} 
                                        imageReference={false}
                                        response_multiple={false}
                                        blockAction={blockAction}
                                        setBlockAction={setBlockAction}
                                        scope={"survey"}
                                        disabled={action === "edit" ? true : false}
                                    />
                                }

                                {questionsList[questionSelectedOrder]?.input_type.value === "comments" &&
                                    <OpenAnswer 
                                        data={null}
                                        questionSelected={questionSelected}
                                        setQuestionSelected={setQuestionSelected}
                                        questionSelectedOrder={questionSelectedOrder}
                                        contentByLang={contentByLang} 
                                        setContentByLang={setContentByLang}
                                        lang={lang}
                                        //data={[dataObjectOpenAnswer()]}
                                        questions={null} 
                                        blockAction={blockAction}
                                        setBlockAction={setBlockAction}
                                        scope={"survey"}
                                        disabled={action === "edit" ? true : false}
                                    />
                                }

                            </div> 

                            
                            </Fragment>

                        : ""
                        } */}



                        {questionsList.length ?
                            <div className='actions'>
                                <ButtonCustom 
                                    className="bt_prev_question"
                                    text={t("texts.Pregunta Anterior")}
                                    onClick={() => !blockAction && handlePrev()}
                                    style_type="normal"
                                    
                                    icon={null}
                                    background={{ normal: panColors.primary, hover: panColors.white }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                    disabled={!blockAction ? (questionSelectedOrder === 0 ? true : false) : true}
                                />
                                {!loadingForm ?
                                    questionSelectedOrder === (questionsList.length - 1) ?
                                        //action !== "edit" &&
                                        <ButtonCustom 
                                            className="bt_next_question"
                                            text={t("texts.Guardar Encuesta")}
                                            onClick={() => !blockAction && handleNext(true)}
                                            //onClick={() => handleNext(true)}
                                            style_type="normal"
                                            
                                            icon={null}
                                            background={{ normal: panColors.primary, hover: panColors.white }}
                                            border={{ normal: panColors.primary, hover: panColors.primary }}
                                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                            disabled={!blockAction ?  questionsList[questionSelectedOrder]?.input_type?.value ? false : true  : blockAction}
                                        />

                                        :
                                        <ButtonCustom 
                                            className="bt_next_question"
                                            text={t("texts.Pregunta Siguiente")}
                                            onClick={() => !blockAction && handleNext()}
                                            style_type="normal"
                                            
                                            icon={null}
                                            background={{ normal: panColors.primary, hover: panColors.white }}
                                            border={{ normal: panColors.primary, hover: panColors.primary }}
                                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                                            disabled={!blockAction ?  questionsList[questionSelectedOrder]?.input_type?.value ? false : true  : blockAction}
                                        />
                                    :
                                    <div className='loader'>
                                        <LoaderComponent size={25} color={panColors.white} />
                                    </div>
                                }


                            </div>
                        :""}
                    </div> : "CARGANDO"}

                </Fragment>
            : 
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={t("texts.Se ha guardado los datos de la encuesta con exito")}
                    resultSuccessText={`${t("texts.Ahora se puede encontrar esta encuesta en la lista de encuestas")} ${vigencySuccessText}.`}
                    buttonBackToListText={t("texts.Volver a listado de encuestas")}
                    buttonCreateNewText={t("texts.Crear encuesta nueva")}
                    buttonCreateContentText={null}
                    buttonCreateEvaluationText={null}
                    buttonBackToListStyle={"invert"}
                    buttonCreateNewStyle={"invert"}
                    buttonCreateContentStyle={"normal"}
                    createContent={null}
                    createEvaluation={null}
                    createNew={"/survey/create"}
                    backTo={"/survey"}
                    setSuccess={setSuccess}
                    type={"create"}
                />
            }
            
        </WrapperStyled>
    );
}

export default React.memo(Form);